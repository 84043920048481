import React from "react";
import {
  Box,
  Grid,
  Slider,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class ContrastSlider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.refSlider = React.createRef();
  }

  componentDidMount = () => {
    this.disabledKeyboarSlider();
  }

  disabledKeyboarSlider = () => {
    if (!this.refSlider.current) {
      setTimeout(() => {
        this.disabledKeyboarSlider();
      }, 100);
      return
    }

    const input = this.refSlider.current.querySelector('input');
    input.addEventListener("keydown", function(event) {
      event.preventDefault();
      return false;
    });
  }

  render () {
    const {
      min,
      max,
      step,
      label,
      value,
      classes,
      onChange,
    } = this.props;

    return (
      <Box className={classes.root}>
        <div className={classes.title}>{ label }</div>
        <Slider
          ref={this.refSlider}
          value={value}
          defaultValue={value}
          size="small"
          min={min}
          max={max}
          step={step}
          aria-label="Small"
          valueLabelDisplay="on"
          onChange={(e, v) => onChange(v)}
        />
      </Box>
    )
  }
}

const styles = {
  root: {
    "& .MuiSlider-valueLabel": {
      top: "initial",
      bottom: -10,
      transform: "translateY(100%) scale(1)",
      background: "transparent",
    },
    "& .MuiSlider-valueLabelLabel": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "110%",
      letterSpacing: "-0.03em",
      color: "#210323",
    }
  },
  title: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "110%",
    letterSpacing: "-0.03em",
    color: "#210323",
  },
};
ContrastSlider = withStyles(styles)(ContrastSlider);

export default ContrastSlider
