import React from "react";
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	Popover,
	Container,
	IconButton,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	MenuRounded as MenuRoundedIcon
} from "@mui/icons-material";
import PopupState, {
	bindTrigger,
	bindPopover
} from 'material-ui-popup-state';
import {palette} from "../../theme/common";
import {
	navigationMenu
} from "../../pages/Dashboard/Dashboard";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";

const HeaderMini = (props) => {
	const classes = useStyles();
	return (
		<div className="header">
			<Container maxWidth="xl">
				<div className="header-content">
					<HeaderMenuElement/>
					<Link to="/" className="header-logo">
						<img src={require('../../assets/image/svg/logos/header-logo.svg').default}/>
					</Link>
				</div>
			</Container>
		</div>
	)
};
const HeaderMenuElement = (props) => {
	const {

	} = props;
	const classes = useStyles();
	const history = useHistory();
	const routeMatch = useRouteMatch();
	const userRole = useSelector((state) => state?.global?.role);

	const handleOpenLink = (state, link) => {
		if (link === routeMatch.url) {
			window.location.reload();
			return
		}

		state.close();
		history.push(link)
	}

	return (
		<PopupState variant="popover">
			{(popupState) => (
				<div>
					<IconButton
						className={classes.headerMenuButton}
						{...bindTrigger(popupState)}
					>
						<MenuRoundedIcon sx={{ color: palette.primary.main }}/>
					</IconButton>
					<Popover
						{...bindPopover(popupState)}
						classes={{ root: classes.headerMenuItems }}
						anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
						transformOrigin={{vertical: 'top', horizontal: 'left'}}
					>
						<List>
							{navigationMenu
								.filter((t) => {
									if ((t?.roles || []).length <= 0) {
										return true
									}
									return (t?.roles || []).includes(userRole)
								})
								.map((item) => (
								<ListItem>
									<ListItemButton
										onClick={handleOpenLink.bind(null, popupState, item?.path)}
									>
										<ListItemIcon>
											<img src={item.icon}/>
										</ListItemIcon>
										<ListItemText primary={item?.label}/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Popover>
				</div>
			)}
		</PopupState>
	)
}

const useStyles = makeStyles(() => ({
	headerMenu: {},
	headerMenuButton: {
		width: 48,
		height: 48,
		borderRadius: "100%",
		backgroundColor: "white!important",
	},
	headerMenuItems: {
		"& .MuiPaper-root": {
			boxShadow: "0px 4px 20px 0px #2103230A",
			border: "1px solid #E6E7EA",
			borderRadius: 12
		},
		"& .MuiList-root": {
			display: "flex",
			flexDirection: "column",
			padding: "24px",
			boxSizing: "border-box",
			gap: 12,
		},
		"& .MuiListItem-root": {
			padding: 0,
		},
		"& .MuiButtonBase-root": {
			padding: 0,
			paddingRight: 24,
			borderRadius: 12,
			border: "1px solid white",

			"&:hover": {
				border: "1px solid #f1dcea",
				background: "linear-gradient(180deg, rgba(167, 38, 129, 0.1) 0%, rgba(167, 38, 129, 0.05) 100%)",
				"& .MuiListItemIcon-root": {
					borderColor: "transparent!important",
				},
				"& .MuiListItemText-root .MuiTypography-root": {
					color: "#A72681"
				}
			}
		},
		"& .MuiListItemIcon-root": {
			width: 48,
			height: 48,
			marginRight: 12,
			borderRadius: 12,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			minWidth: 0,
			border: "1px solid #f1dcea",
			background: "linear-gradient(180deg, rgba(167, 38, 129, 0.1) 0%, rgba(167, 38, 129, 0.05) 100%)",

			"& img": {
				width: 32,
				height: 32,
				objectFit: "contain",
				objectPosition: "center"
			}
		},
		"& .MuiListItemText-root .MuiTypography-root": {
			fontWeight: "500",
			fontSize: "22px",
			lineHeight: "110%",
			letterSpacing: "-0.03em",
			color: "#210323",
		}
	},
}));

export default HeaderMini
export {
	HeaderMenuElement
}
