import React from "react";
import {
	Box,
	Switch,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const SwitchCustom = ({ label, value, onChange }) => {
	const classes = useStyles();

	return (
		<Box
			className={classes.root}
		>
			{label && <Typography className={classes.title}>{ label }</Typography>}
			<Switch
				value={value}
				checked={value}
				className={classes.switch}
				onChange={(e, v) => onChange(v)}
			/>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	title: {
		marginBottom: 10,

		fontSize: 24,
		lineHeight: 1,
		color: "#210323",
		fontWeight: "500",
		letterSpacing: "-0.03em",
	},
	switch: {
		width: 70,

		"& .MuiButtonBase-root": {
			width: 35,
			height: 18,
			top: 2,
			margin: "1px 4px"
		},
		"& .MuiButtonBase-root.Mui-checked": {
			transform: "translateX(27px)"
		},
		"& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track": {
			backgroundColor: "#A72681",
		},
		"& .MuiSwitch-track": {
			width: "100%",
			borderRadius: 8,
		},
		"& .MuiSwitch-thumb": {
			width: "100%",
			height: "100%",
			borderRadius: 6
		}
	},
}));

export default SwitchCustom
