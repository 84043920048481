import React from "react";
import {
	Box,
	Grid,
	Alert,
	Button,
	Typography,
	FormHelperText,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	VisibleContent
} from "../../../../components";
import {palette} from "../../../../theme/common";
import allTranslations from "../../../../locales/allTranslations";

const ResultInfo = (props) => {
	const {
		params,
		isError,
		isLoading
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.section}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography className={classes.sectionTitle}>
						Оправа и параметры центрирования
					</Typography>
				</Grid>
				<Grid item>
					<img
						src={require('../../../../assets/image/svg/logos/header-logo.svg').default}
						className={classes.sectionLogo}
					/>
				</Grid>
			</Grid>
			<Box mt={2}/>

			<VisibleContent visible={Boolean(isError)}>
				<Alert severity="error">Данные замера не найдены</Alert>
			</VisibleContent>
			<VisibleContent visible={Boolean(isLoading)}>
				<Alert severity="info">
					Загружаем результаты замеров
				</Alert>
			</VisibleContent>
			<VisibleContent visible={Boolean(!isError && !isLoading)}>
				<img
					src={require('../../../../assets/image/png/order/glasses.png')}
					className={classes.orderGlasses}
				/>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>{allTranslations('Параметры')}</TableCell>
							<TableCell align="center">{allTranslations('Правый')}</TableCell>
							<TableCell align="center">{allTranslations('Левый')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>{allTranslations('Межзрачковое расстояние')} <strong>(PD)</strong></TableCell>
							<TableCell align="center">{params?.pd?.r} mm</TableCell>
							<TableCell align="center">{params?.pd?.l} mm</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Вертикальное расстояние между зрачками')} <strong>(CVD)</strong></TableCell>
							<TableCell align="center">{params?.cvd?.r} mm</TableCell>
							<TableCell align="center">{params?.cvd?.l} mm</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Пантоскопический угол')} <strong>(PT)</strong></TableCell>
							<TableCell align="center">{params?.pt?.r}°</TableCell>
							<TableCell align="center">{params?.pt?.l}°</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Угол лицевой формы')} <strong>(FFA)</strong></TableCell>
							<TableCell colSpan={2} align="center">{params?.ffa}°</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Высота центрирования')}</TableCell>
							<TableCell align="center">{params?.height?.r} mm</TableCell>
							<TableCell align="center">{params?.height?.l} mm</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Ширина окуляра')} <strong>(BL)</strong></TableCell>
							<TableCell align="center">{params?.box_h?.r} mm</TableCell>
							<TableCell align="center">{params?.box_h?.l} mm</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Высота окуляра')} <strong>(BH)</strong></TableCell>
							<TableCell align="center">{params?.box_v?.r} mm</TableCell>
							<TableCell align="center">{params?.box_v?.l} mm</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{allTranslations('Расстояние между линзами')} <strong>(DBL)</strong></TableCell>
							<TableCell align="center" colSpan={2}>{params?.dbl} mm</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</VisibleContent>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	section: {
		border: "1px solid #E6E7EA",
		background: "white",
		boxShadow: "0px 4px 20px 0px #2103230A",
		padding: "32px 36px",
		boxSizing: "border-box",
		borderRadius: 24
},
	sectionLogo: {
		height: 38,
		objectFit: "contain"
	},
	sectionTitle: {
		fontWeight: "500",
		fontSize: "28px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",
	},
	sectionCaption: {
		fontSize: "16px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},
	errorMessage: {
		fontSize: 18,
		lineHeight: "120%",
		color: palette.error.main,
		fontWeight: "500"
	},

	orderGlasses: {
		height: 88,
		objectFit: "container",
		marginBottom: 32
	},

	table: {
		"& .MuiTableHead-root .MuiTableCell-root": {
			backgroundColor: "transparent",
			borderBottom: "none",

			fontSize: "18px",
			color: "#A72681",
			fontWeight: "500",
			lineHeight: "110%",
			letterSpacing: "-0.03em",
		},
		"& .MuiTableBody-root .MuiTableCell-root": {
			fontSize: "18px",
			lineHeight: "110%",
			letterSpacing: "-0.03em",
			color: "#210323",

			"& strong": {
				fontWeight: "500"
			}
		},
	}
}));

export default ResultInfo
