import agent from "../agent/agent";

const UPDATE_AUTH_ME = 'app/UPDATE_AUTH_ME';
const SET_ROLE = 'app/SET_ROLE';
const SET_EQUIPMENTS = 'app/SET_EQUIPMENTS';

const initialState = {
  authMe: null,
  role: null,

  equipments: [],
};

export function setAuthMe(authMe) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_AUTH_ME,
      authMe
    });
    dispatch({
      type: SET_ROLE,
      role: Boolean(false) ? 'admin' : 'client'
    });
  }
}
export function getEquipments () {
  return async function (dispatch, getState) {
    const state = getState();
    const equipment = state?.global?.authMe?.equipment || null;
    dispatch({
      type: SET_EQUIPMENTS,
      equipments: [
        equipment
      ]
    });
  }
}

export default function AppState(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_AUTH_ME: {
      let authMe = action.authMe

      return {
        ...state,
        authMe
      }
    }
    case SET_ROLE: {
      let role = action.role

      return {
        ...state,
        role
      }
    }
    case SET_EQUIPMENTS: {
      let equipments = action.equipments

      return {
        ...state,
        equipments
      }
    }
    default:
      return state;
  }
}
