import {palette} from "../common";

export default {
	styleOverrides: {
		root: {
			width: 20,
			height: 20,
			border: "1px solid #CDCFD6",
			background: "white",

			"& .MuiSvgIcon-root": {
				display: "none!important",
			},
			"&.Mui-checked": {
				borderColor: palette.primary.main,
				"&:after": {
					content: "''",
					position: "absolute",
					top: '50%', left: "50%",
					transform: "translate(-50%, -50%)",
					width: 8, height: 8,
					background: palette.primary.main,
					borderRadius: "100%"
				}
			}
    }
	}
}
