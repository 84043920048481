import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Rnd
} from "react-rnd";
import {
  palette
} from "../../theme/common";
import {
  TransformWrapper,
  TransformComponent
} from "react-zoom-pan-pinch";
import ContrastSlider from "../ContrastSlider";
import clsx from "clsx";

const originalSize = 1520;

const RefinementEyepieceArea = (props) => {
  const {
    form,
    blobLeft,
    blobRight,
    onChange,
    onSubmit
  } = props;
  const classes = useStyles();

  const refBigForm = React.createRef();
  const [openElement, setOpenElement] = React.useState(null);
  const handleChangeOpenElement = (camera, blob, imageLabel) => {
    setOpenElement({
      imageLabel,
      blob: blob,
      value: form[camera],
      camera: camera
    });
  };
  const handleSubmitOpenForm = () => {
    refBigForm.current.submitForm()
  };
  const handleCloseOpenElement = () => {
    setZoom(0);
    setContrast(0);
    setOpenElement(null)
  };
  const hanldeChangeFormValue = (newFormValue) => {
    let _form = {...form};
    _form[openElement.camera] = newFormValue;
    onChange(_form);

    handleCloseOpenElement();
  };

  const [contrast, setContrast] = React.useState(0);
  const handleChangeContrast = (_contrast) => {
    setContrast(_contrast);
  };

  const [zoom, setZoom] = React.useState(0);
  const handleChangeZoom = (_zoom) => {
    setZoom(_zoom)
  }

  if (!!openElement) {
    return (
      <Box className={classes.root}>
        <Box className={classes.calcContent}>
          <BigSectionImage
            ref={refBigForm}
            {...openElement}
            contrast={contrast}
            initZoom={zoom}
            onSubmit={hanldeChangeFormValue}
            onChangeZoom={handleChangeZoom}
          />
        </Box>
        <Box className={classes.infoContent}>
          <Typography className={classes.title}>
            Уточните область окуляров на <span>{openElement?.imageLabel}</span>
          </Typography>
          <Typography className={classes.message}>На изображении укажите прямоугольные области. Растягивая за границы и углы прямоугольников. Для сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.</Typography>
          <Box mt={5}/>
          <ContrastSlider
            value={contrast}
            min={-100}
            max={100}
            label="Контрастность"
            onChange={handleChangeContrast}
          />
          <Box mt={6}/>
          <ContrastSlider
            value={zoom}
            min={0}
            max={100}
            label="Приближение"
            onChange={handleChangeZoom}
          />
          <Box my={6}/>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                onClick={handleSubmitOpenForm}
              >Сохранить</Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                onClick={handleCloseOpenElement}
              >Отменить</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.calcContent}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SmallSectionImage
              blob={blobLeft}
              value={form.cam_0}
              isChange={true}
              onClick={handleChangeOpenElement.bind(null, 'cam_0', blobLeft, 'фото 1')}
            />
          </Grid>
          <Grid item xs={6}>
            <SmallSectionImage
              blob={blobRight}
              value={form.cam_1}
              isChange={false}
              onClick={handleChangeOpenElement.bind(null, 'cam_1', blobRight, 'фото 2')}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.infoContent}>
        <Typography className={classes.title}>Уточните область окуляров</Typography>
        <Typography className={classes.message}>На изображении укажите прямоугольные области. Растягивая за границы и углы прямоугольников. Для сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.</Typography>
        <Box mt={5}/>
        <Button
          size="small"
          variant="contained"
          onClick={onSubmit}
        >Сохранить</Button>
      </Box>
    </Box>
  )
};
const SmallSectionImage = (props) => {
  const {
    blob,
    value,
    isChange,
    onClick
  } = props;
  const refRoot = React.createRef();
  const [image] = React.useState(() => {
    return URL.createObjectURL(blob);
  });
  const classes = useStyles();

  const [scaleSpan, setScaleSpan] = React.useState(0);
  const [scaleAction, setScaleAction] = React.useState(0);
  React.useEffect(() => {
    initScaleAction();
  }, []);
  const initScaleAction = () => {
    const { width } = refRoot.current.getBoundingClientRect();
    setScaleAction(width / originalSize);
    setScaleSpan(originalSize / width);
  };

  const [_width, setWidth] = React.useState(value?.rect_l?.width);
  const [_height, setHeight] = React.useState(value?.rect_l?.height);
  const [position, setPosition] = React.useState({x: value?.rect_l?.x, y: value?.rect_l?.y});
  const handleChangeSize = (_size, _position) => {
    const getNumber = function (_val) {
      return Number.parseFloat(String(_val || '').replace('px', ''));
    };
    setWidth(_size?.width);
    setHeight(_size?.height);
  };
  const handleChangePosition = (_position) => {
    if (_position.x < 0) {
      _position.x = 0;
    }
    if (_position.y < 0) {
      _position.y = 0;
    }

    if (_position.x + _width > 1520) {
      _position.x = 1520 - _width;
    }
    if (_position.y + _height > 1520) {
      _position.y = 1520 - _height;
    }

    setPosition(_position);
  };

  return (
    <Box className={classes.smallSectionImage} onClick={onClick}>
      <Box ref={refRoot} className={classes.smallSectionImageBox}>
        <img
          src={image}
          className={clsx({
            [classes.smallSectionImageImage]: true,
            '--gray': Boolean(!isChange)
          })}
        />
        <Box
          className={classes.smallSectionImageAction}
          sx={{ transform: `scale(${ scaleAction })` }}
        >
          <Rnd
            bounds="parent"
            scale={scaleAction}
            className={classes.smallSectionImageRnd}
            size={{width: value?.rect_l?.width, height: value?.rect_l?.height}}
            position={{x: value?.rect_l?.x, y: value?.rect_l?.y}}
          ><span style={{ transform: `scale(${ scaleSpan })` }}>Левый</span></Rnd>
          <Rnd
            bounds="parent"
            scale={scaleAction}
            className={classes.smallSectionImageRnd}
            size={{width: value?.rect_r?.width, height: value?.rect_r?.height}}
            position={{x: value?.rect_r?.x, y: value?.rect_r?.y}}
          ><span style={{ transform: `scale(${ scaleSpan })` }}>Правый</span></Rnd>
        </Box>
      </Box>
    </Box>
  )
};

let timeOutChangeZoom = null;
const BigSectionImage = React.forwardRef((props, ref) => {
  const {
    blob,
    value,
    contrast,
    initZoom,
    onSubmit,
    onChangeZoom
  } = props;

  const [localValue, setLocalValue] = React.useState({...value});
  const handleChangeSize = (camera, _size, _position) => {
    const getNumber = function (_val) {
      return Number.parseFloat(String(_val || '').replace('px', ''));
    };

    let _camValue = {...localValue[camera]};
    _camValue.width = getNumber(_size.width);
    _camValue.height = getNumber(_size.height);

    _position = handleChangePosition(camera, _position, true);
    _camValue = {
      ..._camValue,
      ..._position
    };

    let _localValue = {...localValue};
    _localValue[camera] = _camValue;
    setLocalValue(_localValue);
  };
  const handleChangePosition = (camera, _position, isReturn = false) => {
    let _camValue = {...localValue[camera]};

    _position.x = _position.x;
    _position.y = _position.y;

    if (_position.x < 0) {
      _position.x = 0;
    }
    if (_position.y < 0) {
      _position.y = 0;
    }

    if (_position.x + _camValue.width > 1520) {
      _position.x = 1520 - _camValue.width;
    }
    if (_position.y + _camValue.height > 1520) {
      _position.y = 1520 - _camValue.height;
    }

    if (isReturn) {
      return _position
    }

    _camValue = {
      ..._camValue,
      ..._position
    };

    let _localValue = {...localValue};
    _localValue[camera] = _camValue;
    setLocalValue(_localValue);
  };

  const classes = useStyles();
  const [image] = React.useState(() => {
    return URL.createObjectURL(blob);
  });

  const refRoot = React.createRef();
  const [scaleSpan, setScaleSpan] = React.useState(0);
  const [scaleAction, setScaleAction] = React.useState(0);
  React.useEffect(() => {
    initScaleAction();
  }, []);
  const initScaleAction = () => {
    const { width } = refRoot.current.getBoundingClientRect();
    setScaleAction(width / originalSize);
    setScaleSpan(originalSize / width);
  };

  const refZoom = React.createRef();
  const [zoom, setZoom] = React.useState(1);
  React.useEffect(() => {
    document.body.addEventListener('keydown', eventZoomKeyboard, true);
    return () => {
      document.body.removeEventListener('keydown', eventZoomKeyboard, true);
    }
  }, [zoom]);
  React.useEffect(() => {
    clearTimeout(timeOutChangeZoom);
    timeOutChangeZoom = setTimeout(() => {
      if (refZoom.current) {
        const transformState = refZoom.current?.instance?.transformState;
        const newScale = (7 / 100) * initZoom + 1;
        const scale = transformState?.scale;

        const diff = newScale - scale;
        setZoom(newScale);
        handleChangeZoomKeyboard(diff);
      }
    }, 500)
  }, [initZoom]);
  const handleChangeZoom = (event) => {
    setZoom(event?.state?.scale || 1);
    hanldeChangeParentZoom(event?.state?.scale || 1);
  };
  const hanldeChangeParentZoom = (_zoom) => {
    onChangeZoom(Math.round(((_zoom - 1) / 7) * 100));
  };
  const eventZoomKeyboard = ({ code }) => {
    if (code === 'NumpadAdd') {
      handleChangeZoomKeyboard(0.5)
    }
    if (code === 'NumpadSubtract') {
      handleChangeZoomKeyboard(-0.5)
    }
  };
  const handleChangeZoomKeyboard = (val) => {
    if (!refZoom.current) {
      return
    }

    const _scale = refZoom.current?.instance?.transformState?.scale || 1;

    let newScale = _scale + val;
    if (newScale > 8 || newScale < 1) {
      return null
    }

    if (val < 0) {
      refZoom.current.zoomOut(Math.abs(val));
    } else {
      refZoom.current.zoomIn(val);
    }
    setZoom(newScale);
    hanldeChangeParentZoom(newScale);
  };

  React.useImperativeHandle(ref, () => ({
    submitForm() {
      onSubmit(localValue);
    }
  }));

  return (
    <Box className={classes.bigSectionImage}>
      <Box ref={refRoot} className={[classes.bigSectionImageBox, 'big-section-item-root']}>
        <TransformWrapper
          ref={refZoom}
          disablePadding={true}
          panning={{
            excluded: ['panningDisabled'],
            velocityDisabled: false
          }}
          centerZoomedOut={true}
          onZoomStop={handleChangeZoom}
        >
          <TransformComponent>
            <img
              src={image}
              className={classes.bigSectionImageImage}
              style={{filter: `contrast(${contrast + 100}%)`}}
            />

            <Box
              className={classes.smallSectionImageAction}
              sx={{ transform: `scale(${ scaleAction })` }}
            >
              <Rnd
                bounds="parent"
                scale={scaleAction * (zoom)}
                className={[classes.bigSectionImageRnd, 'panningDisabled']}
                size={{width: localValue?.rect_l?.width, height: localValue?.rect_l?.height}}
                position={{x: localValue?.rect_l?.x, y: localValue?.rect_l?.y}}
                onDragStop={(e, d) => handleChangePosition('rect_l', {x: d.x, y: d.y}, false)}
                onResizeStop={(e, direction, ref, delta, position) => handleChangeSize('rect_l', {width: ref?.style?.width, height: ref?.style?.height}, position)}
              ><span style={{ transform: `scale(${ scaleSpan })` }}>Левый</span></Rnd>
              <Rnd
                bounds="parent"
                scale={scaleAction * (zoom)}
                className={[classes.bigSectionImageRnd, 'panningDisabled']}
                size={{width: localValue?.rect_r?.width, height: localValue?.rect_r?.height}}
                position={{x: localValue?.rect_r?.x, y: localValue?.rect_r?.y}}
                onDragStop={(e, d) => handleChangePosition('rect_r', {x: d.x, y: d.y}, false)}
                onResizeStop={(e, direction, ref, delta, position) => handleChangeSize('rect_r', {width: ref?.style?.width, height: ref?.style?.height}, position)}
              ><span style={{ transform: `scale(${ scaleSpan })` }}>Правый</span></Rnd>
            </Box>
          </TransformComponent>
        </TransformWrapper>
      </Box>
    </Box>
  )
});

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    gap: 32
  },
  calcContent: {
    flex: 1,
  },
  infoContent: {
    width: "100%",
    maxWidth: 280,
  },
  title: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "110%",
    letterSpacing: "-0.03em",
    color: "#210323",

    "& span": {
      color: palette.primary.main
    }
  },
  message: {
    marginTop: 12,

    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "130%",
    letterSpacing: "-0.03em",
    color: "#828698",
  },

  smallSectionImage: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid white",
    background: "white",
    borderRadius: 24,
    padding: 6,
    boxSizing: "border-box",
    position: "relative",
    cursor: "pointer",

    "&:hover img": {
      filter: "initial"
    }
  },
  smallSectionImageBox: {
    position: "relative",
    "&:after": {
      content: "''",
      float: "left",
      paddingTop: "100%"
    }
  },
  smallSectionImageRnd: {
    display: "flex!important",
    alignItems: "center",
    justifyContent: "center",
    border: "6px solid #FFE500",
    borderRadius: 20,
    pointerEvents: "none",
    userSelect: "none",

    "& span": {
      display: "flex",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "110%",
      letterSpacing: "-0.03em",
      color: "#FFE500",
      textShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
    }
  },
  smallSectionImageImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0, left: 0,
    borderRadius: 18,
    pointerEvents: "none",
    filter: "grayscale(1)"
  },
  smallSectionImageAction: {
    position: "absolute",
    top: 0, left: 0,
    transformOrigin: "0% 0%",
    width: 1520,
    height: 1520
  },

  bigSectionImage: {
    width: "100%",
    maxWidth: "calc(100% - 312px)",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E6E7EA",
    background: "white",
    borderRadius: 24,
    padding: 6,
    boxSizing: "border-box",
    position: "relative",
    boxShadow: "0px 4px 20px 0px #2103230A",
  },
  bigSectionImageBox: {
    zIndex: 1,
    display: "flex",
    position: "relative",
    borderRadius: 18,
    overflow: "hidden",

    "&:after": {
      content: "''",
      float: "left",
      paddingTop: "100%"
    },
    "& .react-transform-wrapper": {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      top: 0, left: 0,
    },
    "& .react-transform-component": {
      width: "100%",
      height: "100%",
      display: "flex",
    },
  },
  bigSectionImageImage: {
    position: "absolute",
    top: 0, left: 0,
    maxWidth: "100%",
    maxHeight: "100%",
    zIndex: -1,
    pointerEvents: "none",
  },
  bigSectionImageRnd: {
    display: "flex!important",
    alignItems: "center",
    justifyContent: "center",
    border: "6px solid #FFE500",
    borderRadius: 20,

    "& span": {
      display: "flex",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "110%",
      letterSpacing: "-0.03em",
      color: "#FFE500",
      textShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
    }
  },
}));

export default RefinementEyepieceArea
