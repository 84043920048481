import {palette} from "../common";

export default {
	styleOverrides: {
		root: {
			"& .MuiTableCell-root": {
				backgroundColor: palette.primary.main,

				fontSize: 14,
				lineHeight: "16px",
				color: "white",
				fontWeight: "500",

				"&:last-child": {
					borderRadius: "0 16px 0 0"
				},
				"&:first-child": {
					borderRadius: "16px 0 0 0"
				},
			}
		}
	}
}
