import React from "react";
import {
	Container,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {} from "@mui/styles";
import {
	VisibleContent,
	DialogNotification,
	DialogConfirmation,
	DialogSelectedEquipment
} from "../../components";
import {
	HeaderCustom,
	BoxInformationMessage,
	StereoCameraShot,
	OutlineGlasses,
	ContourClarification,
	ClarificationPupilCenter,
	MeasurementResult,
	StepNavigation
} from "./components";
import {
	Notification,
	notificationTypes
} from "../../common/Notification";
import allTranslations from "../../locales/allTranslations";
import agent from "../../agent/agent";
import {getBlobFromBase64, getBlobFromUrl, splitImage} from "../../helpers/snapshotVideo";
import queryString from "query-string";

class FrameMeasurement extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			order: null, // тело заказа

			orderId: null, // ID заказа
			selectedCamera: null, //Выбранная камера
			picture: null, // Снимок с первого этапа
			gyroscope: null, // Положение гироскопа

			pictureFullLeft: null, // не обрезанное изоабрежние с левой камера
			pictureFullRight: null, // не обрезанное изоабрежние с правой камера

			pictureLeftLeft: null,
			pictureLeftRight: null,
			pictureRightLeft: null,
			pictureRightRight: null,

			rects0: null,
			rects1: null,
			cont0l: null,
			cont0r: null,
			cont1l: null,
			cont1r: null,
			center0l: null,
			center0r: null,
			center1l: null,
			center1r: null,

			result: null,

			step: 0,
			maxStep: 0,

			isBackdrop: false
		};

		this.refOutlineGlasses = React.createRef();
		this.refStereoCameraShot = React.createRef();
		this.refDialogConfirmation = React.createRef();
		this.refDialogNotification = React.createRef();
		this.refContourClarification = React.createRef();
		this.refDialogSelectedEquipment = React.createRef();
		this.refClarificationPupilCenter = React.createRef();

		this.timeoutCheckWatchCheckShedulerStep1 = null;
		this.timeoutCheckWatchCheckTasksRectsStep2 = null;
		this.timeoutCheckTasksRefinementContourArea = null;
	}

	componentDidMount = async () => {
		const search = window.location.search;
		const searchObject = queryString.parse(search, {
			arrayFormat: "bracket"
		});
		if (searchObject?.order === 'demo') {
			await this.createOrderBased({})
			return
		}

		await this.initSelectedCamera();
	};

	routeHomePage = () => {
		this.props.history.replace('/')
	}

	// Инициализация и выбор камеры для замера
	initSelectedCamera = async () => {
		const {orderId} = this.state;
		let equipments = this.props?.equipments || [];
		if (equipments.length <= 0) {
			this.refDialogConfirmation.current.open({
				message: "Не обнаружено оборудование. Желаете открыть деморежим?",
				acceptButtonTitle: "Перейти в деморежим.",
				onSubmit: this.createOrderBased.bind(null, equipments?.[0]),
				onCancel: this.routeHomePage.bind(null),
			});
			return
		}

		if (!!orderId) {
			equipments = equipments.filter((t) => !['demo', 'demo-2'].includes(t.id))
		}
		if (equipments.length > 1) {
			this.refDialogSelectedEquipment.current.open({
				list: equipments,
				onSubmit: this.changeSelectedCamera.bind(this)
			})
			return
		}
		await this.createOrder(equipments?.[0]);
	}
	changeSelectedCamera = async (cameraId = null) => {
		const {equipments} = this.props;
		const selectedCamera = [...(equipments || [])].find((t) => Boolean(String(t.id) === String(cameraId)));
		if (selectedCamera?.id === 'demo-2') {
			await this.createOrderBased({})
			return
		}
		await this.createOrder(selectedCamera);
	}

	// Создание заказа
	createOrder = async (camera) => {
		await this.setState({isBackdrop: true});

		const {authMe} = this.props;
		const body = {};
		const res = await agent.post('/orders', body).then((res) => {
			return res.data?.order
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			await this.setState({isBackdrop: false});
			Notification({
				type: notificationTypes.error,
				message: "При создании заказа возникла ошибка, повторите попытку позднее."
			})
			return false
		}

		await this.setState({
			order: res,
			orderId: res?.id,
			selectedCamera: camera,
			step: 1,
			maxStep: 1,
			isBackdrop: false
		});
		return true
	}
	createOrderBased = async (camera) => {
		// const orderId = 628;
		// const order = await agent.get(`/orders/${orderId}`).then((res) => {
		// 	return res.data?.order || null;
		// }).catch(() => {
		// 	return null
		// });
		// if (!order) {
		// 	Notification({
		// 		type: notificationTypes.error,
		// 		message: "Заказ не найден"
		// 	});
		// 	await this.createOrder(camera);
		// 	return
		// }
		//
		// const calculationId = order?.calculations?.[0]?.id;
		// if (!calculationId) {
		// 	Notification({
		// 		type: notificationTypes.error,
		// 		message: "Расчет не найден"
		// 	});
		// 	await this.createOrder(camera);
		// 	return
		// }
		//
		// const calculation = await agent.get(`/calculation/${calculationId}`).then((res) => {
		// }).catch((err) => {
		// 	return null
		// })


		const isSuccess = await this.createOrder(camera);
		if (!isSuccess) {
			return
		}

		const file = await getBlobFromUrl(require('../../assets/Frame 2.jpg'));
		const images = await splitImage(file, {
			width: 3040,
			height: 1520,
		});
		const formData = new FormData();
		formData.append("editableId", camera?.editableId || '00-003');
		formData.append("orderId", this.state.orderId);
		images.map((image) => {
			formData.append("files", image);
		});

		const _uploadFiles = await agent.post('/calculate/upload-files', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		const _taskManager = await agent.post('/calculate/face-key-points', {
			orderId: this.state.orderId
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		await this.checkTaskOrderBased(_taskManager, file);
	}
	checkTaskOrderBased = async (task, file) => {
		const _resTask = await agent.get(`/task-manager/get-task/${ task.id }`).then((res) => {
			const status = res.data?.status;
			if (status === "Success") {
				return res?.data?.responseData?.[0];
			}
			if (status === "Fail") {
				return {error: 'При расчетов параметров произошла ошибка, повторите снимок заново.'}
			}
			return null
		}).catch(() => {
			return {error: 'При расчетов параметров произошла ошибка, повторите снимок заново.'}
		});
		if (_resTask?.error) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.setState({
				step: 1,
				maxStep: 1,
			});
			return
		}
		if (!_resTask) {
			setTimeout(async () => {
				await this.checkTaskOrderBased(task, file);
			}, 1000);
			return
		}
		if (!_resTask?.face_key_points) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.setState({
				step: 1,
				maxStep: 1,
			});
			return
		}

		await this.startWatchCheckShedulerStep1({
			picture: file,
			gyroscope: {x: 0, y: 0, z: 0}
		})
	};

	// Этап съемки
	takePhotoCamera = async () => {
		this.refStereoCameraShot.current.generateSnapshot();
	}
	startWatchCheckShedulerStep1 = async ({picture, gyroscope, taskManager}) => {
		const images = await splitImage(picture, {
			width: 3040,
			height: 1520,
		});
		await this.setState({
			picture,
			gyroscope,

			step: 2,
			maxStep: 2,
			pictureFullLeft: images[0],
			pictureFullRight: images[1],
		});

		const body = {
			"orderId": this.state.orderId,
			"rects": null
		};
		const resRects0 = await agent.post('/calculate/rects0', body).then((res) => {
			return res.data
		}).catch(() => null);
		const isValid0 = await this.checkWatchCheckShedulerStep1V2(resRects0, '0');
		if (!isValid0) {
			return
		}

		const resRects1 = await agent.post('/calculate/rects1', body).then((res) => {
			return res.data
		}).catch(() => null);
		const isValid1 = await this.checkWatchCheckShedulerStep1V2(resRects1, '1');
		if (!isValid1) {
			return
		}
	}
	checkWatchCheckShedulerStep1V2 = async (task, cam) => {
		await new Promise(r => setTimeout(r, 1000));
		const resTask = await agent.get(`/task-manager/get-task/${ task.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (task?.error) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(1);
			return false
		}

		const status = resTask?.status;
		if (status === "Create") {
			return await this.checkWatchCheckShedulerStep1V2(task, cam);
		}
		if (status === "Fail") {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(1);
			return false
		}

		const getRectItem = (_item) => {
			const maxLenght = Math.max(_item?.height,_item?.width);
			return {
				..._item,
				height: maxLenght,
				width: maxLenght,
			}
		};
		const resTaskData = resTask?.responseData?.[0];
		await this.setState({
			[`rects${ cam }`]: {
				[`rect_${ cam }l`]: getRectItem(resTaskData?.[`rect_${ cam }l`]),
				[`rect_${ cam }r`]: getRectItem(resTaskData?.[`rect_${ cam }r`]),
			}
		});
		return true
	};
	checkWatchCheckShedulerStep1 = async (resRects0, resRects1) => {
		clearTimeout(this.timeoutCheckWatchCheckShedulerStep1);

		const checkResRects0 = await agent.get(`/task-manager/get-task/${resRects0.id}`).then((res) => {
			return res.data
		}).catch(() => null);
		const checkResRects1 = await agent.get(`/task-manager/get-task/${resRects1.id}`).then((res) => {
			return res.data
		}).catch(() => null);

		if (checkResRects0.status === "Create" && checkResRects1.status === "Create") {
			this.timeoutCheckWatchCheckShedulerStep1 = setTimeout(async () => {
				await this.checkWatchCheckShedulerStep1(resRects0, resRects1);
			}, 5000);
			return
		}
		if (checkResRects0.status === "Fail" || checkResRects1.status === "Fail") {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(1);
			return
		}

		const getRectItem = (_item) => {
			const maxLenght = Math.max(_item?.height,_item?.width);

			return {
				..._item,
				height: maxLenght,
				width: maxLenght,
			}
		};
		if (checkResRects0.status === "Success" && !this.state.rects0) {
			let _data = checkResRects0?.responseData?.[0];
			delete _data.img_000_0_l;
			delete _data.img_000_0_r;
			await this.setState({
				rects0: {
					rect_0l: getRectItem(_data?.rect_0l),
					rect_0r: getRectItem(_data?.rect_0r),
				}
			});
		}
		if (checkResRects1.status === "Success" && !this.state.rects1) {
			const _data = checkResRects1?.responseData?.[0];
			delete _data.img_000_1_l;
			delete _data.img_000_1_r;
			await this.setState({
				rects1: {
					rect_1l: getRectItem(_data?.rect_1l),
					rect_1r: getRectItem(_data?.rect_1r),
				}
			});
		}

		if (!Boolean(checkResRects0.status === "Success" && checkResRects1.status === "Success")) {
			this.timeoutCheckWatchCheckShedulerStep1 = setTimeout(async () => {
				await this.checkWatchCheckShedulerStep1(resRects0, resRects1);
			}, 5000);
		}
	}

	// Этап уточнения области акуляра
	changeOutlineGlasses = async (name, value) => {
		let state = {...this.state};
		state[name] = value;
		this.setState({...state});
	}
	submitOutlineGlasses = async () => {
		await this.setState({isBackdrop: true});

		const {orderId, rects0, rects1} = this.state;

		const bodyRects0 = {
			orderId: orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
			}
		};
		const bodyRects1 = {
			orderId: orderId,
			rects: {
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			}
		};

		await this.setState({
			step: 3,
			isBackdrop: false,
		});

		const resRects0 = await agent.post('/calculate/rects0', bodyRects0).then((res) => {
			return res.data
			return res.data?.taskManager
		}).catch((err) => {
			return {error: err.response}
		});
		const isValid0 = await this.checkTasksRects(resRects0, '0', 'Left', bodyRects0);
		if (!isValid0) {
			return
		}

		const resRects1 = await agent.post('/calculate/rects1', bodyRects1).then((res) => {
			return res.data
			return res.data?.taskManager
		}).catch((err) => {
			return {error: err.response}
		});
		const isValid1 = await this.checkTasksRects(resRects1, '1', 'Right', bodyRects1);
		if (!isValid1) {
			return
		}

		// await this.checkTasksRects(resRects0, resRects1);
	}
	checkTasksRects = async (resRects, cam, imageDir, bodyRects) => {
		await new Promise(r => setTimeout(r, 500));

		const resTask = await agent.get(`/task-manager/get-task/${ resRects.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (resTask?.error) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		const status = resTask?.status;
		if (status === "Create") {
			return await this.checkTasksRects(resRects, cam, imageDir, bodyRects);
		}
		if (status === "Fail") {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		const resTaskData = resTask?.responseData?.[0];
		await this.setState({
			[`picture${ imageDir }Left`]: await getBlobFromBase64(resTaskData?.[`img_000_${cam}_l`] || ''),
			[`picture${ imageDir }Right`]: await getBlobFromBase64(resTaskData?.[`img_000_${cam}_r`] || '')
		});

		const resContR = await agent.post(`/calculate/cont${ cam }r`, bodyRects).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		const isValidR = await this.checkTasksNodes(resContR, `${cam}r`);
		if (isValidR) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		const resContl = await agent.post(`/calculate/cont${ cam }l`, bodyRects).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		const isValidL = await this.checkTasksNodes(resContl, `${cam}l`);
		if (isValidL) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		return true
	};
	checkTasksNodes = async (task, type) => {
		await new Promise(r => setTimeout(r, 100));
		const resTask = await agent.get(`/task-manager/get-task/${ task.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (resTask?.error) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		const status = resTask?.status;
		if (status === "Create") {
			return await this.checkTasksNodes(task, type);
		}
		if (status === "Fail") {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		const _resData = resTask?.responseData?.[0];
		await this.setState({
			[`cont${ type }`]: (_resData?.nodes || []).map((t) => t.node)
		});
	};

	// Этап уточнение области контуров
	changeRefinementContourArea = (name, value) => {
		this.setState({[name]: value})
	};
	submitRefinementContourArea = async () => {
		await this.setState({
			step: 4,
			maxStep: 4
		});

		const {
			orderId,
			rects0, rects1,
			cont0l, cont0r, cont1l, cont1r
		} = this.state;

		const submitCont0l = await agent.post('/calculate/cont0l', {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
			},
			nodes: [...(cont0l || [])].map((t) => {return {node: t}})
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		const submitCont0r = await agent.post('/calculate/cont0r', {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
			},
			nodes: [...(cont0r || [])].map((t) => {return {node: t}})
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		const submitCont1l = await agent.post('/calculate/cont1l', {
			orderId,
			rects: {
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			},
			nodes: [...(cont1l || [])].map((t) => {return {node: t}})
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		const submitCont1r = await agent.post('/calculate/cont1r', {
			orderId,
			rects: {
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			},
			nodes: [...(cont0r || [])].map((t) => {return {node: t}})
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});


		const resRectEyes0 = await agent.post(`/calculate/rect-eyes0`, {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			}
		}).then((res) => {
			return res.data
		}).catch(() => null);
		const isSuccess0 = await this.checkTasksRefinementContourArea(resRectEyes0, '0');
		if (!isSuccess0) {
			return
		}

		const resRectEyes1 = await agent.post(`/calculate/rect-eyes1`, {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			}
		}).then((res) => {
			return res.data
		}).catch(() => null);
		const isSuccess1 = await this.checkTasksRefinementContourArea(resRectEyes1, '1');
		if (!isSuccess1) {
			return
		}


		const taskRectEyes = await agent.post('/calculate/rect-eyes', {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			}
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		(async () => {await this.checkTasksRefinementContourArea(taskRectEyes)})();
	}
	checkTasksRefinementContourArea = async (task, cam) => {
		await new Promise(r => setTimeout(r, 100));
		const resTask = await agent.get(`/task-manager/get-task/${ task.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (resTask?.error) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(3);
			return false
		}

		const status = resTask?.status;
		if (status === "Create") {
			return await this.checkTasksRefinementContourArea(task, cam);
		}
		if (status === "Fail") {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(2);
			return false
		}

		const _data = resTask?.responseData?.[0];
		await this.setState({
			[`center${ cam }l`]: _data?.[`eyes_${cam}`]?.[`cam_${cam}`]?.cont_l,
			[`center${ cam }r`]: _data?.[`eyes_${cam}`]?.[`cam_${cam}`]?.cont_r,
		});
		return true
	};

	// Этап уточнение центров зрачка
	changeClarificationPupilCenter = (values) => {
		let state = {...this.state};
		values.map((_value) => {
			state[_value.name] = _value.value;
		});
		this.setState(state);
	};
	submitClarificationPupilCenter = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.open({
				title: "Предупреждение",
				message: "Вы уверены что хотите сформировать результат?",
				onSubmit: this.submitClarificationPupilCenter.bind(null, true)
			});
			return
		}

		await this.setState({isBackdrop: true});
		const {
			orderId,
			rects0, rects1,
			center0l, center0r, center1l, center1r
		} = this.state;

		const submitRes = await agent.post('/calculate/rect-eyes', {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			},
			eyes_data: {
				cam_0: {
					cont_l: center0l,
					cont_r: center0r,
				},
				cam_1: {
					cont_l: center1l,
					cont_r: center1r,
				},
			}
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		// await new Promise(r => setTimeout(r, 2000));
		const resultRes = await agent.post('/calculate/calc-full', {
			orderId,
			rects: {
				rect_0l: rects0?.rect_0l,
				rect_0r: rects0?.rect_0r,
				rect_1l: rects1?.rect_1l,
				rect_1r: rects1?.rect_1r,
			},
			eyes_data: {
				cam_0: {
					cont_l: center0l,
					cont_r: center0r,
				},
				cam_1: {
					cont_l: center1l,
					cont_r: center1r,
				},
			}
		}).then((res) => {
			return res?.data
		}).catch((err) => {
			return {error: err.response}
		});
		await this.checkClarificationPupilCenter(resultRes);
	};
	checkClarificationPupilCenter = async (task) => {
		const _resTask = await agent.get(`/task-manager/get-task/${ task.id }`).then((res) => {
			const status = res.data?.status;
			if (status === "Success") {
				return res?.data?.responseData?.[0];
			}
			if (status === "Fail") {
				return {error: 'При расчетов параметров произошла ошибка, повторите снимок заново.'}
			}
			return null
		}).catch(() => {
			return {error: 'При расчетов параметров произошла ошибка, повторите снимок заново.'}
		});
		if (_resTask?.error) {
			Notification({
				type: notificationTypes.error,
				message: 'При расчетов параметров произошла ошибка, повторите снимок заново.'
			});
			await this.changeStepFromError(4);
			return
		}
		if (!_resTask) {
			setTimeout(async () => {
				await this.checkClarificationPupilCenter(task);
			}, 5000);
			return
		}

		await this.setState({
			step: 5,
			maxStep: 5,
			result: _resTask,
			isBackdrop: false
		});
	};

	changeStepFromError = (_step) => {
		if (_step === 1) {
			return
		}
		if (_step === 2) {
			this.setState({
				step: _step,
				isBackdrop: false,

				rects0: null,
				rects1: null,

				blobLeftLeft: null,
				blobLeftRight: null,
				blobRightLeft: null,
				blobRightRight: null,

				cont0l: null,
				cont0r: null,
				cont1l: null,
				cont1r: null,

				center0l: null,
				center0r: null,
				center1l: null,
				center1r: null
			});
			return
		}
		if (_step === 3) {
			this.setState({
				step: _step,
				isBackdrop: false,

				blobLeftLeft: null,
				blobLeftRight: null,
				blobRightLeft: null,
				blobRightRight: null,

				cont0l: null,
				cont0r: null,
				cont1l: null,
				cont1r: null,

				center0l: null,
				center0r: null,
				center1l: null,
				center1r: null
			});
			return
		}
		if (_step === 4) {
			this.setState({
				step: _step,
				center0l: null,
				center0r: null,
				center1l: null,
				center1r: null,
				isBackdrop: false
			});
			return
		}
	}
	changeStepFromNavigation = (_step) => {
		this.setState({ step: _step });
	}

	_headerButtonLabel = () => {
		const {step} = this.state;
		if (step === 1) {
			return allTranslations('Сделать снимок')
		}
		if (step === 2 || step === 3 || step === 4) {
			return "Далее"
		}
		return ""
	}
	_headerButtonAction = async () => {
		const { step } = this.state;

		if (step === 1) {
			await this.takePhotoCamera();
		}
		if (step === 2) {
			if (this.refOutlineGlasses?.current?.state?.editElement) {
				document.getElementById('OutlineGlassesSubmit').click();
				return
			}
			await this.submitOutlineGlasses();
		}
		if (step === 3) {
			if (this.refContourClarification?.current?.state?.fullItem) {
				document.getElementById('ContourClarificationSubmit').click();
				return
			}
			await this.submitRefinementContourArea();
		}
		if (step === 4) {
			if (this.refClarificationPupilCenter?.current?.state?.editForm) {
				document.getElementById('ClarificationPupilCenterSubmit').click();
				return
			}
			await this.submitClarificationPupilCenter(false);
		}
	}
	_headerButtonDisabled = () => {
		const { step } = this.state;

		if (step === 2) {
			const { rects0, rects1 } = this.state;
			return Boolean(!rects0 || !rects1)
		}
		if (step === 3) {
			const {
				pictureLeftLeft, pictureLeftRight,
				pictureRightLeft, pictureRightRight,
				cont0l, cont0r, cont1l, cont1r,
			} = this.state;
			return Boolean(
				!pictureLeftLeft || !pictureLeftRight || !pictureRightLeft || !pictureRightRight ||
			 	!cont0l || !cont0r || !cont1l || !cont1r
			)
		}
		if (step === 4) {
			const {
				center0l, center0r,
				center1l, center1r
			} = this.state;
			return Boolean(!center0l || !center0r || !center1l || !center1r);
		}

		return false
	}

	render() {
		const {
			orderId,
			selectedCamera,

			pictureFullLeft,
			pictureFullRight,

			pictureLeftLeft,
			pictureLeftRight,
			pictureRightLeft,
			pictureRightRight,

			rects0,
			rects1,
			cont0l,
			cont0r,
			cont1l,
			cont1r,
			center0l,
			center0r,
			center1l,
			center1r,
			result,

			step,
			maxStep,

			isBackdrop
		} = this.state;

		return (
			<>
				<HeaderCustom
					label={`№ ${orderId}`}
					caption={`Камера:<br/>${selectedCamera?.editableId || 'Демо режим'}`}
					buttonLabel={this._headerButtonLabel()}
					buttonDisabled={this._headerButtonDisabled()}
					onClickRight={this._headerButtonAction}
				/>

				<Container maxWidth="xl">
					<StepNavigation
						active={step}
						maxStep={maxStep}
						onChange={this.changeStepFromNavigation}
					/>

					<VisibleContent visible={Boolean(step === 0)}>
						<Container maxWidth="sm">
							<BoxInformationMessage message="Выберите ваше устройство из списка"/>
						</Container>
					</VisibleContent>
					<VisibleContent visible={Boolean(step === 1)}>
						<StereoCameraShot
							ref={this.refStereoCameraShot}
							orderId={orderId}
							cameraId={selectedCamera?.editableId}
							onChangeSelectedCamera={() => {
							}}
							onChange={this.startWatchCheckShedulerStep1}
						/>
					</VisibleContent>
					<VisibleContent visible={Boolean(step === 2)}>
						<OutlineGlasses
							ref={this.refOutlineGlasses}
							blobLeft={pictureFullLeft}
							blobRight={pictureFullRight}

							rects0={rects0}
							rects1={rects1}

							onChangeRects0={this.changeOutlineGlasses.bind(null, 'rects0')}
							onChangeRects1={this.changeOutlineGlasses.bind(null, 'rects1')}
						/>
					</VisibleContent>
					<VisibleContent visible={Boolean(step === 3)}>
						<ContourClarification
							ref={this.refContourClarification}
							blobLeftLeft={pictureLeftLeft}
							blobLeftRight={pictureLeftRight}
							blobRightLeft={pictureRightLeft}
							blobRightRight={pictureRightRight}

							rects0={rects0}
							rects1={rects1}

							cont0l={cont0l}
							cont0r={cont0r}
							cont1l={cont1l}
							cont1r={cont1r}

							onChange={this.changeRefinementContourArea}
						/>
					</VisibleContent>
					<VisibleContent visible={Boolean(step === 4)}>
						<ClarificationPupilCenter
							ref={this.refClarificationPupilCenter}
							blobLeftLeft={pictureLeftLeft}
							blobLeftRight={pictureLeftRight}
							blobRightLeft={pictureRightLeft}
							blobRightRight={pictureRightRight}

							rects0={rects0}
							rects1={rects1}
							center0l={center0l}
							center0r={center0r}
							center1l={center1l}
							center1r={center1r}

							onChange={this.changeClarificationPupilCenter}
						/>
					</VisibleContent>
					<VisibleContent visible={Boolean(step === 5)}>
						<MeasurementResult result={result}/>
					</VisibleContent>
				</Container>


				<DialogConfirmation ref={this.refDialogConfirmation}/>
				<DialogNotification ref={this.refDialogNotification}/>
				<DialogSelectedEquipment ref={this.refDialogSelectedEquipment}/>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		)
	}
};

export default FrameMeasurement
