import {palette} from "../common";

export default {
	styleOverrides: {

		root: {
			borderRadius: 10,
			boxShadow: "none",
			padding: "15px 20px",

			fontWeight: "500",
			fontSize: 18,
			lineHeight: "22px",
			textAlign: "center",
			textTransform: "initial",


			"&.Mui-disabled": {
				backgroundColor: "#8E8E8E",
				color: "#FFFFFF"
			}
		},

		containedSecondary: {},
		containedError: {
			color: "white"
		},
		containedSuccess: {
			color: "white"
		},

		colorInherit: {
			backgroundColor: "#F7F7F7"
		},

		outlined: {
			borderColor: palette.primary.main,
			color: palette.primary.main
		},

		sizeSmall: {
			padding: "8px 16px",
			borderRadius: 8,
		},
    sizeLarge: {
      padding: "14px 32px",
    },

		tableAction: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: 32, height: 32,
			padding: 4,
			boxSizing: "border-box",
			minWidth: "initial",
			borderRadius: "100%",
			backgroundColor: "#F2F3F6",
			border: "1px solid #2103230F",

			"& svg": {
				color: palette.primary.main,
				width: "100%", height: "100%",
			}
		}
	}
}
