import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Typography,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	SwitchCustom,
	DialogConfirmation
} from "../../components";

class Settings extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.refDialogConfirmation = React.createRef();
	}

	componentDidMount = () => {
	}

	logout = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.open({
				message: "Вы действительно хотите выйти из системы?",
				onSubmit: this.logout.bind(null, true),
				acceptButtonTitle: "Да, выйти"
			});
			return
		}
	}

	render() {
		const {
			classes
		} = this.props;

		return (
			<>
        <Box className={classes.section}>
	        <Typography className={classes.title}>Настройки</Typography>
	        <Grid container spacing={2}>
		        <Grid item xs={12}>
			        <Grid container alignItems="center" justifyContent="space-between">
				        <Grid item>
					        <Typography className={classes.formLabel}>Инвертировать изображение?</Typography>
				        </Grid>
				        <Grid item>
					        <SwitchCustom/>
				        </Grid>
			        </Grid>
		        </Grid>
	        </Grid>
        </Box>
				<Box className={classes.sectionAction}>
					<Button
						fullWidth
						size="large"
						variant="contained"
					>Сохранить</Button>
					<Button
						fullWidth
						size="small"
						variant="outlined"
						color="secondary"
						sx={{mt: 1}}
						onClick={this.logout.bind(null, false)}
					>
						Выход из системы
					</Button>
				</Box>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>
			</>
		);
	}
}

const styles = {
	root: {},
	title: {
		marginBottom: 24,
		fontSize: 28,
		lineHeight: 1,
		color: "#210323",
		fontWeight: "500",
		letterSpacing: "-0.03em",
	},
	section: {
		width: "100%",
		maxWidth: "640px",
		margin: "0 auto",
		background: "white",
		boxShadow: "0px 4px 20px 0px #2103230A",
		borderRadius: 24,
		border: "1px solid #E6E7EA",
		padding: "30px 36px",
		boxSizing: "border-box"
  },
	sectionAction: {
		marginTop: 8,
		width: "100%",
		maxWidth: "640px",
		margin: "0 auto",
		"& .MuiButtonBase-root": {
			borderRadius: 24
		}
	},
	formLabel: {
		fontSize: 18,
		lineHeight: 1,
		color: "#210323",
		// fontWeight: "500",
		letterSpacing: "-0.03em",
	}
};
Settings = withStyles(styles)(Settings);

export default Settings
