import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import clsx from "clsx";
import {notificationTypes} from "../../common/Notification";
import {palette} from "../../theme/common";

class DialogNotification extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      message: "",
      type: null,
      isOpen: false
    }
  }


  open = (params = {}) => {
    this.setState({
      title: params?.title || "",
      message: params?.message || "",
      type: params?.type || null,
      isOpen: true
    })
  }
  close = () => {
    this.setState({
      title: "",
      message: "",
      type: null,
      isOpen: false
    })
  }

  render () {
    const {
      title,
      message,
      type,
      isOpen
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        className={clsx({
          [classes.root]: true,
          '--info': Boolean(type === notificationTypes.info),
          '--error': Boolean(type === notificationTypes.error),
          '--success': Boolean(type === notificationTypes.success),
          '--warning': Boolean(type === notificationTypes.warning),
        })}
        onClose={this.close}
      >
        <DialogTitle>
          <Typography className={classes.title}>
            { title }
          </Typography>
          <IconButton className={classes.close} onClick={this.close}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            textAlign="center"
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = {
  root: {
    "&.--success .MuiPaper-root": {
      borderColor: palette.success.main,
      backgroundColor: palette.success.main,

      "& .MuiTypography-root": {
        color: palette.success.contrastText
      },
      "& .MuiSvgIcon-root": {
        color: palette.success.contrastText
      },
    },
    "&.--error .MuiPaper-root": {
      borderColor: palette.error.main,
      backgroundColor: palette.error.main,

      "& .MuiTypography-root": {
        color: palette.error.contrastText
      },
      "& .MuiSvgIcon-root": {
        color: palette.error.contrastText
      },
    }
  },
  title: {
    fontSize: 24,
    lineHeight: 1,
    color: "#210323",
    fontWeight: "500",
    letterSpacing: "-0.03em"
  },
  message: {
    fontSize: 15,
    lineHeight: 1.4,
    color: "#828698",
    fontWeight: "500",
    letterSpacing: "-0.03em"
  },
  close: {
    position: "absolute",
    top: "5px",
    right: "5px",
  }
};
DialogNotification = withStyles(styles)(DialogNotification);

export default DialogNotification
