import React from "react";
import {
	Box,
	Button
} from "@mui/material";
import {

} from "@mui/styles";
import {
	Close as CloseIcon
} from "@mui/icons-material";
import {
	DialogConfirmation
} from "../../../../components"

const ResetChanged = ({ onClick }) => {

	const refDialogConfirmation = React.createRef();
	const handleReset = (isConfirm) => {
		if (!isConfirm) {
			refDialogConfirmation.current.open({
				message: "Вы действительно хотите сбросить измененные данные?",
				acceptButtonTitle: "Да, сбросить",
				onSubmit: handleReset.bind(null, true)
			});
			return
		}
		onClick();
	}

	return (
		<Box>
			<Button
				variant="text"
				sx={{
					display: "flex",
					alignItems: "center",
					padding: "8px 20px",
					borderRadius: 0,
					color: "#828698",
					fontSize: 14,
					lineHeight: 1
				}}
				onClick={handleReset.bind(null, false)}
			>
				Сбросить изменения
				<CloseIcon sx={{color: "#828698", fontSize: 18, marginLeft: 1, marginTop: "-1px"}}/>
			</Button>

			<DialogConfirmation ref={refDialogConfirmation}/>
		</Box>
	)
}

export default ResetChanged
