import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,
	CircularProgress,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	withStyles,
	makeStyles
} from "@mui/styles";
import {Notification, notificationTypes} from "../../../../common/Notification";
import {snapshotVideo, splitImage} from "../../../../helpers/snapshotVideo";
import {getPositionGyroscope} from "../../../../helpers/calibration";
import {palette} from "../../../../theme/common";
import getAllCameras from "../../../../helpers/getAllCameras";
import allTranslations from "../../../../locales/allTranslations";
import clsx from "clsx";
import agent from "../../../../agent/agent";

class StereoCameraShot extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			listCamera: [],
			previewPictureUrl: "",
			selectedCamera: null,
			previewPicture: null,
			isLoadedVideo: false
		};
		this.refVideo = React.createRef();
	}

	componentDidMount = async () => {
		await this.initListCamera();
	}

	initListCamera = async () => {
		const listCamera = await getAllCameras();
		this.setState({
			listCamera,
			selectedCamera: listCamera?.[0]?.deviceId || null
		}, async () => {
			await this.startCamera();
		});

		this.props.onChangeSelectedCamera(listCamera?.[0]?.deviceId || null)
	}

	// Запуск и замены камеры
	startCamera = async () => {
		const {selectedCamera} = this.state;
		if (!selectedCamera) {
			Notification({
				type: notificationTypes.error,
				message: allTranslations('Мы не обнаружили ни одной камеры')
			})
			return
		}

		const device = await navigator.mediaDevices.getUserMedia({
			video: {
				deviceId: selectedCamera,
				height: 1520,
				width: 3040
			}
		});

		this.refVideo.current.pause();
		this.refVideo.current.srcObject = device;
		this.refVideo.current.play();
	}
	changeCamera = async (selectedCamera) => {
		const stream = this.refVideo.current.srcObject;
		const tracks = stream.getTracks();
		tracks.forEach(function (track) {
			track.stop();
		});
		this.refVideo.current.srcObject = null;

		this.setState({selectedCamera}, async () => {
			await this.startCamera();
		});
	}

	// Сгенерировать снимок
	generateSnapshot = async () => {
		const imageSizeWidth = 3040;
		const imageSizeHeight = 1520;
		const video = document.getElementById('video-content');
		const blob = await snapshotVideo(video, {
			width: imageSizeWidth,
			height: imageSizeHeight,
		});
		this.setState({
			previewPicture: blob,
			previewPictureUrl: URL.createObjectURL(blob)
		});
	}
	successSnapshot = (taskManager) => {
		const {
			previewPicture,
		} = this.state;
		this.props.onChange({
			picture: previewPicture,
			taskManager
		})
		this.cancelSnapshot();
	}
	cancelSnapshot = () => {
		this.setState({
			previewPicture: null,
			previewPictureUrl: ""
		})
	}

	render() {
		const {
			classes,
			orderId,
			cameraId,
		} = this.props;
		const {
			isLoadedVideo,
			selectedCamera,
			previewPicture,
			previewPictureUrl
		} = this.state;

		return (
			<>
				<Box className={classes.root}>
					<Box className={classes.calcContent}>
						<video
							autoPlay
							id="video-content"
							ref={this.refVideo}
							onLoadedData={() => this.setState({isLoadedVideo: true})}
						/>
					</Box>
					<Box className={classes.infoContent}>
						<div className={classes.title}>Сделайте,<br/>снимок</div>
						<div className={classes.message}>
							Расположитесь так, чтобы лицо попадало в кадр. Дождитесь успешной подсветки. После нажмите вверху на
							“Сделать снимок”.
						</div>
					</Box>
				</Box>

				{Boolean(previewPictureUrl) && (
					<DialogConfirmSnapshot
						preview={previewPicture}
						orderId={orderId}
						cameraId={cameraId}
						previewUrl={previewPictureUrl}
						onSuccess={this.successSnapshot}
						onCancel={this.cancelSnapshot}
					/>
				)}
			</>
		)
	}
}

let timeoutCheckTaskManager = null;
const DialogConfirmSnapshot = React.memo((props) => {
	const {
		preview,
		previewUrl,
		orderId,
		cameraId,
		onCancel,
		onSuccess
	} = props;
	const classes = useStyles();

	const [isLoading, setLoading] = React.useState(true);
	const [taskManager, setTaskManager] = React.useState(null);

	React.useEffect(() => {
		(async () => {
			await startProcessCheckImages();
		})();
		return function () {
			clearTimeout(timeoutCheckTaskManager)
		}
	}, []);
	const startProcessCheckImages = async () => {
		const images = await splitImage(preview, {
			width: 3040,
			height: 1520,
		});
		const formData = new FormData();
		formData.append("editableId", cameraId);
		formData.append("orderId", orderId);
		images.map((image) => {
			formData.append("files", image);
		});

		const _uploadFiles = await agent.post('/calculate/upload-files', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		const _taskManager = await agent.post('/calculate/face-key-points', {
			orderId: orderId
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (_taskManager?.error) {
			Notification({
				type: notificationTypes.error,
				message: "Возникла ошибки при отправке изображение на тест, повторите попытку позднее."
			});
			onCancel();
			return
		}

		setTaskManager(_taskManager);
		await checkTaskManager(_taskManager);
	};
	const checkTaskManager = async (initTaskManager) => {
		clearTimeout(timeoutCheckTaskManager);
		const _taskManager = taskManager || initTaskManager;

		const res = await agent.get(`/task-manager/get-task/${ _taskManager.id }`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		});

		const isCompleted = Boolean(res.status === 'Success');
		if (isCompleted) {
			if (!res?.responseData?.[0]?.face_key_points) {
				Notification({
					type: notificationTypes.error,
					message: "При расчете замеров произошла ошибка, сделайте снимок повторно"
				})
				onCancel();
				return
			}
			if (res?.responseData?.[0]?.face_key_points) {
				setLoading(false);
				return
			}
			return
		}

		const isFailed = Boolean(res.status === 'Fail');
		if (isFailed) {
			Notification({
				type: notificationTypes.error,
				message: "При расчете замеров произошла ошибка, сделайте снимок повторно"
			})
			onCancel();
			return
		}

		timeoutCheckTaskManager = setTimeout(async () => {
			await checkTaskManager(_taskManager);
		}, 3000);
	}

	return (
		<Dialog
			open={Boolean(previewUrl)}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle sx={{ background: "#A72681" }}>
				<Typography variant="h3" sx={{ color: "white", textAlign: "left" }}>
					{allTranslations('Подтверждение снимка')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box className={classes.content}>
					<Box className={clsx([classes.imageBox, 'left'])}>
						<img src={previewUrl}/>
						{Boolean(isLoading) && (
							<Box className={classes.imageBoxLoading}>
								<CircularProgress/>
							</Box>
						)}
					</Box>
					<Box className={clsx([classes.imageBox, 'right'])}>
						<img src={previewUrl}/>
						{Boolean(isLoading) && (
							<Box className={classes.imageBoxLoading}>
								<CircularProgress/>
							</Box>
						)}
					</Box>
				</Box>
				<Grid container spacing={1} justifyContent="flex-end">
					<Grid item>
						<Button
							size="small"
							variant="outlined"
							onClick={onCancel}
						>Сделать снимок заного</Button>
					</Grid>
					<Grid item>
						<Button
							size="small"
							variant="contained"
							disabled={isLoading}
							onClick={onSuccess.bind(null, taskManager)}
						>Продолжить</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
})

const styles = {
	root: {
		display: "flex",
		gap: 32
	},
	calcContent: {
		display: "flex",
		flexDirection: "column",
		gap: 16,
		flex: 1,
    position: "relative",
    overflow: "hidden",
		backgroundColor: "white",
		border: "1px solid #E6E7EA",
		boxShadow: "0px 4px 20px 0px #2103230A",
		borderRadius: 24,
    padding: 10,
    boxSizing: "border-box",

		"& video": {
			width: "100%",
			height: "100%",
      borderRadius: 18
		}
	},
	infoContent: {
		width: "100%",
		maxWidth: 280,
	},
	title: {
		fontWeight: "500",
		fontSize: "24px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",

		"& span": {
			color: palette.primary.main
		}
	},
	message: {
		marginTop: 12,

		fontWeight: "500",
		fontSize: "15px",
		lineHeight: "130%",
		letterSpacing: "-0.03em",
		color: "#828698",
	}
};
StereoCameraShot = withStyles(styles)(StereoCameraShot);

const useStyles = makeStyles(() => ({
	content: {
		display: "flex",
		gap: 16,
		padding: "20px 0",
		width: "100%",
		boxSizing: "border-box",
	},
	imageBox: {
		flex: "auto",
		borderRadius: 24,
		padding: 8,
		boxSizing: "border-box",
		background: "white",
		position: "relative",
		overflow: "hidden",
		border: "8px solid white",
		boxShadow: "0 0 0 1px #E6E7EA",

		"& img": {
			position: "absolute",
			top: "0",
			left: "0",
			height: "100%",
			objectFit: "cover",
		},
		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "100%"
		},
		"& .right img": {
			left: "initial",
			right: 0,
		}
	},
	imageBoxLoading: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: 0, left: 0,
		width: "100%",
		height: "100%",

		"& .MuiCircularProgress-root": {
			width: "92px!important",
			height: "92px!important",
		}
	}
}));

export default StereoCameraShot
