import React from "react";
import {
	Box,
	Button,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const ButtonPrevNext = ({ label, IconEnd, IconStart, onClick }) => {
	const classes = useStyles();

	const handleClick = () => {
		if (!onClick) {
			return null
		}
		onClick();
	};

	return (
		<Box className={classes.root}>
			<Button
				size="small"
				color="secondary"
				variant="contained"
				className={classes.button}
				onClick={handleClick}
			>
				{IconStart && <IconStart/>}
				<span dangerouslySetInnerHTML={{__html: label}}/>
				{IconEnd && <IconEnd/>}
			</Button>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "flex-start",
	},
	button: {
		display: "flex",
		alignItems: "center",
		padding: "12px 14px",
		gap: 8,
		borderRadius: 10,

		fontSize: 14,
		lineHeight: 1,
		textAlign: "left"
	}
}));

export default ButtonPrevNext
