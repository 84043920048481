import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Button,
  Skeleton,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import moment from "moment";
import {useHistory} from "react-router-dom";
import allTranslations from "../../../../locales/allTranslations";
import {idFormat} from "../../../../helpers/formaters";

const TableCustom = (props) => {
  const {
    data,
    isLoad,
    page,
    total,

    onRemove,
    onChangePage
  } = props;

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{allTranslations('Пациент')}</TableCell>
            <TableCell align="right">{allTranslations('Дата создания')}</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoad && data.map((t) => <RowData data={t} onRemove={onRemove}/>)}
          {isLoad && <RowLoading/>}
        </TableBody>
      </Table>

      <Box mt={2}/>

      <Grid container justifyContent="flex-end">
        <Grid item>
          <Pagination
            page={page}
            count={total}
            onChange={(e, t) => onChangePage(t)}
          />
        </Grid>
      </Grid>

      <Box mt={2} height="10px"/>

    </>
  )
}
const RowData = React.memo((props) => {
  const {
    data,
    onRemove
  } = props;
  const history = useHistory()

  const handleRoutePage = () => {
    window.open(`/orders/${data.id}`, '_blank');
  }

  return (
    <TableRow hover onClick={handleRoutePage}>
      <TableCell sx={{whiteSpace: "nowrap"}}>{idFormat(data.id, '#####')}</TableCell>
      <TableCell>
        {[data?.patient?.firstName, data?.patient?.lastName, data?.patient?.patronymic].filter((t) => !!t).join(" ")}
      </TableCell>
      <TableCell align="right">
        {moment(data?.createdAt).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell align="right">
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="tableAction" onClick={onRemove.bind(this, data, false)}>
              <CloseIcon sx={{fontSize: 24}}/>
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (
    <>
      {Array(10).fill(1).map((t) => (
        <TableRow>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell align="right"><Skeleton/></TableCell>
          <TableCell align="right"><Skeleton/></TableCell>
        </TableRow>
      ))}
    </>
  )
})

export default React.memo(TableCustom)
