// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import FrameMeasurement from './FrameMeasurement';

export default compose(
  connect(
    state => ({
      authMe: state?.global?.authMe || null,
      equipments: state?.global?.equipments || []
    }),
    dispatch => ({}),
  ),
)(FrameMeasurement);
