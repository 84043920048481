import MuiAutocomplete from "./MuiAutocomplete";
import MuiButton from "./MuiButton";
import MuiTypography from "./MuiTypography";
import MuiInputBase from "./MuiInputBase";
import MuiTooltip from "./MuiTooltip";
import MuiSlider from "./MuiSlider";

import MuiRadio from "./MuiRadio";
import MuiCheckbox from "./MuiCheckbox";
import MuiFormControlLabel from "./MuiFormControlLabel";

import MuiTextField from "./MuiTextField";
import MuiOutlinedInput from "./MuiOutlinedInput";

import MuiSwitch from "./MuiSwitch";

import MuiBackdrop from "./MuiBackdrop";

import MuiDialog from "./MuiDialog";
import MuiDialogContent from "./MuiDialogContent";

import MuiPagination from "./MuiPagination";

import MuiTable from "./MuiTable";
import MuiTableHead from "./MuiTableHead";
import MuiTableBody from "./MuiTableBody";

import MuiContainer from "./MuiContainer";

export default {
  MuiAutocomplete,
  MuiButton,
  MuiTypography,
  MuiInputBase,
  MuiTooltip,
  MuiSlider,

  MuiRadio,
  MuiCheckbox,
  MuiFormControlLabel,

  MuiTextField,
  MuiOutlinedInput,

  MuiSwitch,

  MuiBackdrop,

  MuiDialog,
  MuiDialogContent,

  MuiPagination,

  MuiTable,
  MuiTableHead,
  MuiTableBody,

  MuiContainer
}
