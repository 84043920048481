import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  HeaderMini,
  RouteWithLayout
} from "../components";
import {
  Cabinet as CabinetLayouts
} from "../layouts";
import {
  Users as UsersPage,
  Orders as OrdersPage,
  Partners as PartnersPage,
  Settings as SettingsPage,
  Dashboard as DashboardPage,
  Equipment as EquipmentPage,
  OrderView as OrderViewPage,
  Calibration as CalibrationPage,
  PartnerView as PartnerViewPage,
  CreateFrom1c as CreateFrom1cPage,
  FrameMeasurement as FrameMeasurementPage,
} from "../pages";
import {useSelector} from "react-redux";
const localPages = [
  {
    path: '/',
    component: DashboardPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },

  {
    path: '/calibration',
    component: CalibrationPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/users',
    component: UsersPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/partners',
    component: PartnersPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/partners/:id',
    component: PartnerViewPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/equipment',
    component: EquipmentPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/orders',
    component: OrdersPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/orders/created',
    component: FrameMeasurementPage,
    // componentPatient: FrameMeasurementPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/orders/:id',
    component: OrderViewPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
  {
    path: '/create-from-1c',
    component: CreateFrom1cPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },

  {
    path: '/settings',
    component: SettingsPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true,
    Header: HeaderMini
  },
]

const RegistrationRoutes = () => {
  const isPatient = useSelector((state) => Boolean(state?.patient?.patient));
  return (
    <Switch>
      {
        localPages.map((page, idx) => {
          let component = page.component;
          // if (isPatient && page?.componentPatient) {
          //   component = page?.componentPatient;
          // }


          return (
            <RouteWithLayout
              key={'page-' + idx}
              {...page}
              component={component}
            />
          )
        })
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export default RegistrationRoutes;
