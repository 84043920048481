import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	Container,
	Typography,
	CircularProgress,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import {
	Notification,
	notificationTypes
} from "../../common/Notification";
import * as Yup from "yup";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class AdminAuth extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				editableId: "00-003",
				password: "password123",
			},

			isBackdrop: false
		};

		this.refFormik = React.createRef();
	}

	// Изменение формы
	changeForm = ({target}) => {
		const {name, value} = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}

	// Авторизация пользователя в системе
	onSubmit = async (body) => {
		this.setState({isBackdrop: true});

		const response = await agent.post('/auth', body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: true}
		});
		if (response?.error) {
			this.setState({isBackdrop: false});

			Notification({
				message: allTranslations('ошибка_авторизации'),
				type: notificationTypes.error
			})

			return
		}

		localStorage.setItem('token', response?.accessToken);
		agent.defaults.headers['x-auth-token'] = response?.accessToken;

		const authMe = await agent.get('/auth/me').then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		this.setState({isBackdrop: false});
		this.props.setAuthMe(authMe);
	}

	render() {
		const {
			form,
			isBackdrop
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<Box className={classes.root}>
				<Box className={classes.section}>
					<Typography className={classes.title}>Вход в систему</Typography>
					<Formik
						innerRef={this.refFormik}
						initialValues={form}
						validationSchema={validationSchema}
						onSubmit={this.onSubmit}
					>{(props) => {
						const {
							values,
							errors,
							touched,

							handleSubmit
						} = props;

						return (
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										value={values.editableId}
										error={Boolean(touched.editableId && errors.editableId)}
										helperText={touched.editableId && errors.editableId}
										onChange={this.changeForm}

										fullWidth
										name="editableId"
										variant="outlined"
										label="Номер оборудования"
										placeholder="Номер оборудования"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										value={values.password}
										error={Boolean(touched.password && errors.password)}
										helperText={touched.password && errors.password}
										onChange={this.changeForm}

										fullWidth
										label="Пароль"
										name="password"
										variant="outlined"
										placeholder="Пароль"
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										fullWidth
										size="large"
										variant="contained"
										onClick={handleSubmit}
									>Войти</Button>
								</Grid>
							</Grid>
						)
					}}</Formik>
				</Box>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "center",
		height: "100vh",
		backdrop: "#F3F3F3",
		paddingTop: 160,
		boxSizing: "border-box"
	},
	section: {
		width: "100%",
		maxWidth: 640,
		background: "#FFFFFF",
		border: "1px solid #E6E7EA",
		borderRadius: 24,
		padding: 36,
		paddingTop: 30,
		boxSizing: "border-box"
	},
	title: {
		marginBottom: 32,
		fontSize: 28,
		lineHeight: 1,
		color: "#210323",
		fontWeight: "500",
		letterSpacing: "-0.03em",
	},

};
AdminAuth = withStyles(styles)(AdminAuth);

const validationSchema = Yup.object().shape({
	editableId: Yup.string()
		.matches(/^\d{2}?-\d{3}$/, "Некорректный номер камеры")
		.required(allTranslations('Заполните поле')),
	password: Yup.string().required(allTranslations('Заполните поле')),
});

export default AdminAuth
