export default {
	styleOverrides: {

		root: {
			height: 6,
		},

		rail: {
			opacity: 1
		},

		thumb: {
			boxShadow: "0px 4px 20px 0px #21032329",
			background: "white",
			border: "1px solid #E6E7EA",
			height: "16px",
			width: "42px",
			borderRadius: "4px",
		}

	}
}
