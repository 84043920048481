import {palette} from "../common";

export default {
	styleOverrides: {
		root: {
			"& .MuiTableRow-root": {
				"&:nth-child(2n)": {
					"& .MuiTableCell-root": {
						background: "#F2F3F6",
					},
				}
			},
			"& .MuiTableCell-root": {
				fontSize: 14,
				lineHeight: "16px",
				color: palette.black,

				"&:last-child": {
					borderRadius: "0 8px 8px 0"
				},
				"&:first-child": {
					borderRadius: "8px 0 0 8px"
				},
			},
		}
	}
}
