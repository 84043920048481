export default {
	styleOverrides: {

		root: {
			padding: "0 20px!important",
			boxSizing: "border-box"
		}

	}
}
