import React from "react";
import {
	Box,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import clsx from "clsx";

const stepItems = [
	{
		step: 1,
		label: 'Съёмка',
		icon: require('../../../../assets/image/svg/frame-measurement/filming.svg').default,
	},
	{
		step: 2,
		label: 'Область окуляров',
		icon: require('../../../../assets/image/svg/frame-measurement/eyepiece-area.svg').default,
	},
	{
		step: 3,
		label: 'Утончение контуров',
		icon: require('../../../../assets/image/svg/frame-measurement/refining-contours.svg').default,
	},
	{
		step: 4,
		label: 'Центры зрачков',
		icon: require('../../../../assets/image/svg/frame-measurement/pupil-centers.svg').default,
	},
	{
		step: 5,
		label: 'Результат',
		icon: require('../../../../assets/image/svg/frame-measurement/result.svg').default,
	},
];

const StepNavigation = (props) => {
	const {
		active,
		maxStep,
		onChange
	} = props;
	const classes = useStyles();

	const handleClickStep = (_step) => {
		if (_step > maxStep) {
			return
		}
		onChange(_step);
	};

	return (
		<Box
			className={classes.root}
		>
			{stepItems.map((item) => (
				<Box
					key={`stepItems-${ item.step }`}
					className={clsx({
						[classes.button]: true,
						"--active": Boolean(+item.step === +active),
						"--disabled": Boolean(+item.step > +maxStep),
					})}
					onClick={handleClickStep.bind(null, +item.step)}
				>
					<img src={ item.icon }/>
					<span>{ item.label }</span>
				</Box>
			))}
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: 4,
		boxSizing: "border-box",
		background: "rgba(0,0,0,0.03)",
		borderRadius: 8,
		position: "relative",
		overflow: "hidden",
		marginBottom: 28
	},
	button: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 12,
		boxSizing: "border-box",
		cursor: "pointer",
		borderRadius: 4,
		flex: "auto",

		"& img": {
			width: 24,
			height: 24,
			objectFit: "contain",
			objectPosition: "center",
			marginRight: 6,
			filter: "invert(59%) sepia(1%) saturate(6713%) hue-rotate(216deg) brightness(92%) contrast(78%)",
			opacity: 0.5
		},
		"& span": {
			fontWeight: "500",
			fontSize: "15px",
			lineHeight: "15px",
			letterSpacing: "-0.03em",
			color: "#828698",
		},
		"&.--active": {
			background: "white",
			boxShadow: "0px 4px 20px 0px #2103230A",

			"& img": {
				opacity: 1,
				filter: "invert(21%) sepia(51%) saturate(2830%) hue-rotate(292deg) brightness(98%) contrast(95%)",
			},
			"& span": {
				color: "#210323"
			}
		},
		"&.--disabled": {
			cursor: "not-allowed",
			opacity: 0.6,
		}
	}
}));

export default StepNavigation
