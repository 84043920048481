import React from "react";
import {

} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class Cabinet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render () {
    const {
      classes,
      children,
      Header
    } = this.props;

    return (
      <div className={classes.root}>
        {Boolean(Header) && <Header/>}
        <div className={classes.body}>
          { children }
        </div>
      </div>
    )
  }
};

const styles = {
  root: {
    position: "relative",
  },
  header: {},
  body: {
    paddingTop: 96,
    paddingBottom: 24,
    boxSizing: "border-box"
  },
};
Cabinet = withStyles(styles)(Cabinet);

export default Cabinet
