import React from "react";
import {
	Box, Button, Grid, Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import clsx from "clsx";
import {
	Rnd
} from "react-rnd";
import {
	palette
} from "../../../../theme/common";
import {
	getOriginalSizeImage
} from "../../../../helpers/snapshotVideo";
import {ContrastSlider} from "../../../../components";
import ResetChanged from "../common/ResetChanged";

const centerImagePath = require('../../../../assets/image/center.svg').default;

class ClarificationPupilCenter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editForm: null
		}
	}

	openEditForm = (item) => {
		if (item.disabled) {
			return
		}
		this.setState({editForm: item});
	}
	closeEditForm = () => {
		this.setState({editForm: null});
	}
	submitEditForm = (changedValue) => {
		const { editForm } = this.state;

		let values = [];
		if (editForm.type === "left") {
			values.push({name: "center0l", value: changedValue['0']});
			values.push({name: "center1l", value: changedValue['1']});
		}
		if (editForm.type === "right") {
			values.push({name: "center0r", value: changedValue['0']});
			values.push({name: "center1r", value: changedValue['1']});
		}

		this.props.onChange(values);
		this.nextStep();
	}

	nextStep = () => {
		const { editForm } = this.state;
		const {
			blobLeftLeft,
			blobRightLeft,
			rects0, rects1,
			center0l, center1l
		} = this.props;

		if (editForm.type === "right") {
			this.closeEditForm();
			this.openEditForm({
				blobLeft: blobLeftLeft,
				blobRight: blobRightLeft,
				rectLeft: rects0?.rect_0l,
				rectRight: rects1?.rect_1l,
				centerLeft: center0l,
				centerRight: center1l,
				type: "left",
				label: "левых глазах",
				caption: "Л"
			});
			return
		}
		if (editForm.type === "left") {
			this.closeEditForm();
		}
	}

	render() {
		const {
			blobLeftLeft,
			blobLeftRight,
			blobRightLeft,
			blobRightRight,

			rects0,
			rects1,

			center0l,
			center0r,
			center1l,
			center1r,
		} = this.props;
		const {
			editForm
		} = this.state;

		if (!!editForm) {
			return (
				<EditContent
					key={`EditContent-${ Date.now() }`}
					item={editForm}
					onClose={this.closeEditForm}
					onSubmit={this.submitEditForm}
				/>
			)
		}
		return (
			<SmallContent
				key={`SmallContent-${ Date.now() }`}
				blobLeftLeft={blobLeftLeft}
				blobLeftRight={blobLeftRight}
				blobRightLeft={blobRightLeft}
				blobRightRight={blobRightRight}

				rectLL={rects0?.rect_0l}
				rectLR={rects0?.rect_0r}
				rectRL={rects1?.rect_1l}
				rectRR={rects1?.rect_1r}

				center0l={center0l}
				center0r={center0r}
				center1l={center1l}
				center1r={center1r}

				onOpen={this.openEditForm}
			/>
		)
	}
};
const SmallContent = (props) => {
	const {
		blobLeftLeft,
		blobLeftRight,
		blobRightLeft,
		blobRightRight,

		rectLL,
		rectLR,
		rectRL,
		rectRR,

		center0l,
		center0r,
		center1l,
		center1r,

		onOpen
	} = props;
	const classes = useStyles();

	const [imagesPath] = React.useState(() => {
		return {
			"0l": URL.createObjectURL(blobLeftLeft),
			"0r": URL.createObjectURL(blobLeftRight),
			"1l": URL.createObjectURL(blobRightLeft),
			"1r": URL.createObjectURL(blobRightRight),
		}
	});

	return (
		<Box className={classes.root}>
			<Box className={classes.calcContent}>
				<Box
					className={clsx({
						[classes.smallItemBox]: true,
						'--loading': Boolean(!center0r || !center1r)
					})}
					onClick={onOpen.bind(this, {
						blobLeft: blobLeftRight,
						blobRight: blobRightRight,
						rectLeft: rectLR,
						rectRight: rectRR,
						centerLeft: center0r,
						centerRight: center1r,
						type: "right",
						label: "правых глазах",
						caption: "П",
						disabled: Boolean(!center0r || !center1r)
					})}
				>
					<div className={classes.smallItemBoxTitle}>
						Правые
					</div>
					<SmallContentItem
						image={imagesPath['0r']}
						center={center0r}
						caption="П-0"
						rect={rectLR}
						blob={blobLeftRight}
					/>
					<SmallContentItem
						image={imagesPath['1r']}
						center={center1r}
						caption="П-1"
						rect={rectRR}
						blob={blobRightRight}
					/>
				</Box>
				<Box
					className={clsx({
						[classes.smallItemBox]: true,
						'--loading': Boolean(!center0l || !center1l)
					})}
					onClick={onOpen.bind(this, {
						blobLeft: blobLeftLeft,
						blobRight: blobRightLeft,
						rectLeft: rectLL,
						rectRight: rectRL,
						centerLeft: center0l,
						centerRight: center1l,
						type: "left",
						label: "левых глазах",
						caption: "Л",
						disabled: Boolean(!center0l || !center1l)
					})}>
					<div className={classes.smallItemBoxTitle}>
						Левые
					</div>
					<SmallContentItem
						image={imagesPath['0l']}
						center={center0l}
						caption="Л-0"
						rect={rectLL}
						blob={blobLeftLeft}
					/>
					<SmallContentItem
						image={imagesPath['1l']}
						center={center1l}
						caption="Л-1"
						rect={rectRL}
						blob={blobRightLeft}
					/>
				</Box>
			</Box>
			<Box className={classes.infoContent}>
				<Typography className={classes.title}>Уточните центр зрачков</Typography>
				<Typography className={classes.message}>
					Выберите метку нужного размера и передвиньте её по центру зрачка. Следующее фото по кнопке “Далее”. Для
					сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.
				</Typography>
			</Box>
		</Box>
	)
};
const SmallContentItem = (props) => {
	const {
		blob,
		rect,
		image,
		center,
		caption,
	} = props;
	const classes = useStyles();

	const refImage = React.createRef();
	const [scaleSpan, setScaleSpan] = React.useState(0);
	const [scaleAction, setScaleAction] = React.useState(0);
	const initState = async () => {
		if (!refImage.current) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageClientWidth,
			height: imageClientHeight,
		} = refImage.current.getBoundingClientRect();
		if (imageClientWidth <= 0 || imageClientHeight <= 0) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageOriginalWidth,
			height: imageOriginalHeight,
		} = await getOriginalSizeImage(blob);

		setScaleSpan(imageOriginalWidth / imageClientWidth);
		setScaleAction(imageClientWidth / imageOriginalWidth);
	};
	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, []);

	return (
		<div
			className={clsx({
				[classes.smallItemBoxImage]: true,
				'--loading': Boolean(!center)
			})}
		>
			<div className={classes.smallItemBoxContent}>
				<img ref={refImage} src={image}/>
				<div className={classes.smallItemBoxCaption}>{caption}</div>
				<Box
					className={classes.smallItemBoxAction}
					sx={{
						transform: `scale(${scaleAction})`,
						marginTop: `${(rect.y * -1) * scaleAction}px`,
						marginLeft: `${(rect.x * -1) * scaleAction}px`,
					}}
				>
					{Boolean(center) && (
						<div
							className={classes.smallItemBoxCenter}
							style={{top: center?.y, left: center?.x}}
						><img src={centerImagePath}
						      style={{transform: `scale(${scaleSpan}) translate(${-16 * scaleAction}px,${-16 * scaleAction}px)`}}/>
						</div>
					)}
				</Box>
			</div>
		</div>
	)
};

const EditContent = (props) => {
	const {
		item,

		onClose,
		onSubmit
	} = props;
	const classes = useStyles();

	const [contrast, setContrast] = React.useState(0);
	const handleChangeContrast = (_contrast) => {
		setContrast(_contrast);
	};

	const [scaleCenter, setScaleCenter] = React.useState(1);
	const handleChangeScaleCenter = (_scaleCenter) => {
		setScaleCenter(_scaleCenter);
	};

	const [value0, setValue0] = React.useState({x: item?.centerLeft?.x, y: item?.centerLeft?.y});
	const [value1, setValue1] = React.useState({x: item?.centerRight?.x, y: item?.centerRight?.y});
	const changeValue = (name, value) => {
		if (name === 'left') {
			setValue0(value)
		}
		if (name === 'right') {
			setValue1(value)
		}
	};

	const handleSubmit = () => {
		onSubmit({
			"0": value0,
			"1": value1,
		})
	};

	return (
		<Box className={classes.root}>
			<Box className={clsx([classes.calcContent, classes.calcContentEdit])}>
				<EditContentItem
					blob={item.blobLeft}
					rect={item.rectLeft}
					index={0}
					value={value0}
					scaleCenter={scaleCenter}
					center={item.centerLeft}
					contrast={contrast}
					caption={item.caption}

					onChange={changeValue.bind(this, 'left')}
				/>
				<EditContentItem
					blob={item.blobRight}
					rect={item.rectRight}
					index={1}
					value={value1}
					contrast={contrast}
					scaleCenter={scaleCenter}
					center={item.centerRight}
					caption={item.caption}

					onChange={changeValue.bind(this, 'right')}
				/>
			</Box>
			<Box className={classes.infoContent}>
				<Typography className={classes.title}>Уточните центр зрачков<br/>на <span>{item.label}</span></Typography>
				<Typography className={classes.message}>
					Выберите метку нужного размера и передвиньте её по центру зрачка. Следующее фото по кнопке “Далее”. Для
					сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.
				</Typography>
				<Box mt={5}/>
				<ContrastSlider
					value={contrast}
					min={-10} max={10}
					label="Контрастность"
					onChange={handleChangeContrast}
				/>
				<Box mt={5}/>
				<ContrastSlider
					value={scaleCenter}
					min={0.2} max={5}
					step={0.1}
					label="Размер зрачка"
					onChange={handleChangeScaleCenter}
				/>
				<Box mt="auto"/>
				<Box sx={{opacity: 0, pointerEvents: "none"}}>
					<Button id="ClarificationPupilCenterSubmit" size="small" variant="contained" fullWidth onClick={handleSubmit}>
						Сохранить изменения
					</Button>
				</Box>
				<ResetChanged onClick={onClose}/>
			</Box>
		</Box>
	)
};
const EditContentItem = (props) => {
	const {
		blob,
		rect,
		value,
		index,
		center,
		caption,
		contrast,
		scaleCenter,

		onChange
	} = props;
	const classes = useStyles();

	const refImage = React.createRef();
	const [imagePath] = React.useState(() => {
		return URL.createObjectURL(blob);
	});

	const [isInit, setInit] = React.useState(false);
	const [scaleSpan, setScaleSpan] = React.useState(1);
	const [scaleAction, setScaleAction] = React.useState(1);
	const initState = async () => {
		if (!refImage.current) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}
		const {width: imageClientWidth} = refImage.current.getBoundingClientRect();
		if (imageClientWidth <= 0) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageOriginalWidth,
			height: imageOriginalHeight,
		} = await getOriginalSizeImage(blob);

		setScaleSpan(imageOriginalWidth / imageClientWidth);
		setScaleAction(imageClientWidth / imageOriginalWidth);
		setInit(true);
	};

	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, []);

	const handleChangePosition = (event, position) => {
		onChange({
			x: position.x + 16,
			y: position.y + 16,
		})
	};

	return (
		<Box className={classes.editItemBox}>
			<Box className={classes.editItemBoxMedia}>
				<img
					ref={refImage}
					src={imagePath}
					className={classes.editItemBoxImage}
					style={{
						filter: `contrast(${1 + (contrast / 10)})`
					}}
				/>
				<Box
					className={classes.editItemBoxAction}
					sx={{
						transform: `scale(${scaleAction})`,
						marginTop: `${(rect.y * -1) * scaleAction}px`,
						marginLeft: `${(rect.x * -1) * scaleAction}px`,
					}}
				>
					{Boolean(isInit) && (
						<Rnd
							bounds="parent"
							className={classes.editItemBoxRnd}
							scale={scaleAction}
							position={{x: value.x - 16, y: value.y - 16}}
							size={{width: 32, height: 32}}
							enableResizing={false}
							onDragStop={handleChangePosition}
						>
							<img
								src={centerImagePath}
								style={{
									transform: `scale(${scaleCenter*scaleSpan})`,
								}}
							/>
						</Rnd>
					)}
					<div style={{
						backgroundColor: "red",
						width: 1, height: 1,
						borderRadius: "100%",
						position: "absolute",
						top: value.y, left: value.x
					}}/>
				</Box>
			</Box>
			<div className={classes.smallItemBoxCaption}>
				{caption}-{index}
			</div>
		</Box>
	)
};

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: 32
	},
	calcContent: {
		display: "flex",
		flexDirection: "column",
		gap: 16, flex: 1,
	},
	calcContentEdit: {
		gap: 20,
		flexDirection: "row",

		"& > *": {
			width: "calc(50% - 0px)"
		}
	},

	smallItemBox: {
		display: "flex",
		gap: 24,
		backgroundColor: "white",
		border: "1px solid #E6E7EA",
		borderRadius: 24,
		padding: 6,
		boxSizing: "border-box",
		cursor: "pointer",

		"&:not(.--loading):hover": {
			backgroundColor: "#210323",
			borderColor: "#A72681",
			boxShadow: "0px 4px 20px 0px #21032333",

			"& $smallItemBoxTitle": {
				color: "white"
			},
			"& $smallItemBoxImage": {
				borderColor: "#A72681"
			}
		},
		"&.--loading": {
			"& img": {
				filter: "grayscale(1)"
			}
		}
	},
	smallItemBoxTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flex: "auto",
		fontSize: 24,
		lineHeight: 1,
		fontWeight: "500",
		letterSpacing: '-0.03em',
		color: "#210323"
	},
	smallItemBoxImage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "35%",
		position: "relative",
		overflow: "hidden",
		borderRadius: 18,
		backgroundColor: "rgba(0,0,0,0.03)",
		border: "1px solid #E6E7EA",

		"& img": {
			// position: "absolute",
			// top: "50%", left: "50%",
			// transform: "translate(-50%, -50%)",
			maxWidth: "100%",
			maxHeight: "100%"
		},
		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "100%"
		},
		"&.--loading": {
			cursor: "progress",

			"& img": {
				filter: "grayscale(1)"
			},
			"&:before": {
				content: "'Идет расчет параметров'",
				position: "absolute",
				top: "0",
				left: "0",
				width: "100%",
				height: "100%",
				zIndex: "10",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				fontSize: "20px",
				fontWeight: "500",
				lineHeight: "1",
				letterSpacing: "-0.03em",
				textAlign: "center",
				color: "white",
			}
		},
	},
	smallItemBoxContent: {
		display: "flex",
		position: "relative"
	},
	smallItemBoxCenter: {
		position: "absolute",
		width: 0, height: 0,
		pointerEvents: "none",
		"& img": {
			position: "absolute",
			width: 32, height: 32,
			maxWidth: "initial",
			maxHeight: "initial",
			filter: "invert(84%) sepia(47%) saturate(2025%) hue-rotate(359deg) brightness(108%) contrast(103%)",
		}
	},
	smallItemBoxCaption: {
		position: "absolute",
		left: 16, bottom: 16,
		pointerEvents: "none",

		fontSize: 14,
		lineHeight: 1,
		color: "#A72681",
		fontWeight: "500",
		letterSpacing: "-0.03em",
		textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff"
	},
	smallItemBoxAction: {
		width: 1520,
		height: 1520,
		position: "absolute",
		top: 0, left: 0,
		transformOrigin: "0% 0%",
		pointerEvents: "none",
	},

	editItemBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #E6E7EA",
		borderRadius: 24,
		backgroundColor: "white",
		padding: 8,
		boxSizing: "border-box",
		position: "relative",
	},
	editItemBoxMedia: {
		display: "flex",
		position: "relative",
		overflow: "hidden",
	},
	editItemBoxImage: {
		maxWidth: "100%",
		borderRadius: 18,
	},
	editItemBoxAction: {
		width: 1520,
		height: 1520,
		position: "absolute",
		top: 0, left: 0,
		transformOrigin: "0% 0%",
	},
	editItemBoxRnd: {
		display: "flex!important",
		userSelect: "none!important",

		"& img": {
			pointerEvents: "none",
			filter: "invert(84%) sepia(47%) saturate(2025%) hue-rotate(359deg) brightness(108%) contrast(103%)"
		}
		// backgroundImage: `url(${ centerImagePath })`,
		//
	},

	infoContent: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: 280,
	},
	title: {
		fontWeight: "500",
		fontSize: "24px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",

		"& span": {
			color: palette.primary.main
		}
	},
	message: {
		marginTop: 12,

		fontWeight: "500",
		fontSize: "15px",
		lineHeight: "130%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},
	buttonPrevNext: {
		display: "flex",
		alignItems: "center",
		gap: 8,
		padding: 14,
		background: "#A726811A",

		fontSize: 14,
		lineHeight: 1.1,
		color: "#A72681",
		textAlign: "left",

		"& .__badge": {
			backgroundColor: "#A72681",
			color: "white",
			padding: "5px 6px 2px",
			borderRadius: 4
		},
		"&:hover": {
			color: "white",
			"& .__badge": {
				backgroundColor: "white",
				color: "#A72681",
			},
		}
	},
	canvasLines: {
		position: "absolute",
		top: 0, left: 0,
		// width: "100%",
		// height: "100%",
		userSelect: "none",
		pointerEvents: "none"
	}
}));

export default ClarificationPupilCenter
