import {palette} from "../common";

export default {
	styleOverrides: {
		root: {
			background: "white",
			border: "1px solid #E6E7EA",
			boxSizing: "border-box",
			borderRadius: 12,
			boxShadow: "0px 2px 20px 0px #00000008",

			"& .MuiOutlinedInput-notchedOutline": {
				border: "none"
			},

			"& .MuiOutlinedInput-input": {
				padding: "15px 20px",
				fontSize: "16px",
				lineHeight: "20px"
			},

			"& .MuiInputBase-inputMultiline": {
				padding: "15px 30px"
			}
		}
	}
}
