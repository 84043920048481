import React from "react";
import {
	Box,
	Grid,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Rnd
} from "react-rnd";
import {
	palette
} from "../../theme/common";
import {
	getOriginalSizeImage
} from "../../helpers/snapshotVideo";
import clsx from "clsx";
import ContrastSlider from "../ContrastSlider";

const ContourRefinement = (props) => {
	const {
		values,
		formStage2
	} = props;
	const classes = useStyles();

	const [openChanged, setOpenChanged] = React.useState(null);
	const handleOpenChanged = (direction) => {

		let _openChanged = {
			direction,
			label: Boolean(direction === 'right') ? 'правых глазах' : 'левых глазах',
			caption: Boolean(direction === 'right') ? "П-" : "Л-",

			center0: values?.cam_0?.cont_r,
			image0: formStage2.blobLeftRight,
			offset0: formStage2?.cam_0?.rect_r,

			center1: values?.cam_1?.cont_r,
			image1: formStage2.blobRightRight,
			offset1: formStage2?.cam_1?.rect_r,
		};

		setOpenChanged(_openChanged)
	};
	const handleCloseChanged = () => {
	};
	const handleSubmitChanged = () => {
	};


	if (!!openChanged) {
		return (
			<ParentEditForm
				{...openChanged}
				onClose={handleCloseChanged}
				onSubmit={handleSubmitChanged}
			/>
		)
	}
	return (
		<Box className={classes.root}>
			<Box className={classes.calcContent}>
				<ItemCamera
					label="Правые"
					caption="П-"

					center0={values?.cam_0?.cont_r}
					image0={formStage2.blobLeftRight}
					offset0={formStage2?.cam_0?.rect_r}

					center1={values?.cam_1?.cont_r}
					image1={formStage2.blobRightRight}
					offset1={formStage2?.cam_1?.rect_r}

					onClick={handleOpenChanged.bind(null, 'right')}
				/>
				<ItemCamera
					label="Левые"
					caption="Л-"

					center0={values?.cam_0?.cont_l}
					image0={formStage2.blobLeftLeft}
					offset0={formStage2?.cam_0?.rect_l}

					center1={values?.cam_1?.cont_l}
					image1={formStage2.blobRightLeft}
					offset1={formStage2?.cam_1?.rect_l}

					onClick={handleOpenChanged.bind(null, 'left')}
				/>
			</Box>
			<Box className={classes.infoContent}>
				<div className={classes.title}>Уточните центр зрачков</div>
				<div className={classes.message}>
					Выберите метку нужного размера и передвиньте её по центру зрачка. Следующее фото по кнопке “Далее”. Для
					сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.
				</div>
			</Box>
		</Box>
	)
};
const ItemCamera = (props) => {
	const {
		label,
		caption,

		image0,
		center0,
		offset0,

		image1,
		center1,
		offset1,

		onClick
	} = props;
	const classes = useStyles();

	const [imageUrl0] = React.useState(() => {
		return URL.createObjectURL(image0)
	});
	const [imageUrl1] = React.useState(() => {
		return URL.createObjectURL(image1)
	});

	return (
		<Box
			className={classes.sectionCamera}
			onClick={onClick}
		>
			<div className={clsx([classes.sectionCameraLabel, 'sectionCameraLabel'])}>
				{label}
			</div>
			<div className={classes.sectionCameraItem}>
				<ElementImageCenterDotMin
					blob={image0}
					center={center0}
					offset={offset0}
					imageUrl={imageUrl0}
				/>

				<span className={classes.sectionCameraItemImageCaption}>
		      {`${caption}0`}
	      </span>
			</div>
			<div className={classes.sectionCameraItem}>
				<ElementImageCenterDotMin
					blob={image1}
					center={center1}
					offset={offset1}
					imageUrl={imageUrl1}
				/>
				<span className={classes.sectionCameraItemImageCaption}>
					{`${caption}1`}
				</span>
			</div>
		</Box>
	)
};
const ElementImageCenterDotMin = (props) => {
	const {
		blob,
		center,
		offset,
		imageUrl
	} = props;
	const classes = useStyles();

	const refImage = React.createRef();
	const [scaleSpan, setScaleSpan] = React.useState(0);
	const [scaleAction, setScaleAction] = React.useState(0);
	const [calcCenter, setCalcCenter] = React.useState({...center});
	const [originalSize, setOriginalSize] = React.useState({width: 0, height: 0});

	const initState = async () => {

		const _size = await getOriginalSizeImage(blob);
		setOriginalSize(_size);

		let _center = {...center};
		_center.x = _center.x - offset.x;
		_center.y = _center.y - offset.y;
		setCalcCenter(_center);

		const {
			width: imageWidth
		} = refImage.current.getBoundingClientRect();

		setScaleSpan(_size.width / imageWidth);
		setScaleAction(imageWidth / _size.width);
	}
	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, [imageUrl, center]);

	return (
		<>
			<img
				ref={refImage}
				src={imageUrl}
				className={classes.sectionCameraItemImage}
			/>
			<div
				style={{
					width: originalSize.width,
					height: originalSize.height,
					transform: `scale(${scaleAction})`
				}}
				className={classes.sectionCameraItemImageCenter}
			>
				<span style={{
					top: calcCenter.y,
					left: calcCenter.x,
					transform: `scale(${scaleSpan})`
				}}/>
			</div>
		</>
	)
};

const ParentEditForm = (props) => {
	const {
		label,
		caption
	} = props;
	const classes = useStyles();
	const classesForm = useStylesForm();

	const [imageUrl0] = React.useState(() => {
		return URL.createObjectURL(props.image0)
	});
	const [imageUrl1] = React.useState(() => {
		return URL.createObjectURL(props.image1)
	});

	const [contrast, setContrast] = React.useState(0);
	const handleChangeContrast = (_contrast) => {
		setContrast(_contrast);
	};

	const [zoom, setZoom] = React.useState(1);
	const handleChangeZoom = (_zoom) => {
		setZoom(_zoom)
	}

	const [dotPercentSize, setDotPercentSize] = React.useState(0);
	const handleChangeDotPercentSize = (_val) => {
		setDotPercentSize(_val);
	}

	const [center0, setCenter0] = React.useState(null);
	const [center1, setCenter1] = React.useState(null);
	const handleInitCenter = (index, value) => {
		if (index === '0') {
			setCenter0(value);
		}
		if (index === '1') {
			setCenter1(value);
		}
	};
	const handleChangeCenter = (index, value) => {
		if (index === '0') {
			handleChangeCenter0(value);
		}
		if (index === '1') {
			setCenter1(value);
		}
	};
	const handleChangeCenter0 = (value) => {
		const _oldValue = {...center0};

		const _newCenter1 = {...center1};
		_newCenter1.x = _newCenter1.x + (value.x - _oldValue.x);
		_newCenter1.y = _newCenter1.y + (value.y - _oldValue.y);

		setCenter0(value);
		setCenter1(_newCenter1);
	};

	const handleChangeZoomKeyboard = (_val) => {
		let _zoom = zoom + _val;
		if (_zoom < 1 || _zoom > 8) {
			return
		}
		setZoom(_zoom)
	}
	const handleZoomKeyboard = ({ code }) => {
		if (code === 'NumpadAdd') {
			handleChangeZoomKeyboard(0.5)
		}
		if (code === 'NumpadSubtract') {
			handleChangeZoomKeyboard(-0.5)
		}
	}
	React.useEffect(() => {
		document.body.addEventListener('keydown', handleZoomKeyboard, true);
		return () => {
			document.body.removeEventListener('keydown', handleZoomKeyboard, true);
		}
	}, [zoom]);

	return (
		<Box className={classes.root}>
			<Grid container spacing={3} sx={{flex: 1}}>
				<Grid item xs={6}>
					<Box className={classesForm.sectionCameraItem}>
						<ItemGlassFormElement
							zoom={zoom}
							center={center0}
							image={imageUrl0}
							blob={props.image0}
							contrast={contrast}
							offset={props.offset0}
							initCenter={props.center0}
							dotPercentSize={dotPercentSize}
							onInitCenter={handleInitCenter.bind(null, '0')}
							onChangeCenter={handleChangeCenter.bind(null, '0')}
						/>
						<span className={classes.sectionCameraItemImageCaption}>{`${caption}0`}</span>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box className={classesForm.sectionCameraItem}>
						<ItemGlassFormElement
							zoom={zoom}
							center={center1}
							image={imageUrl1}
							blob={props.image1}
							contrast={contrast}
							offset={props.offset1}
							initCenter={props.center1}
							dotPercentSize={dotPercentSize}
							onInitCenter={handleInitCenter.bind(null, '1')}
							onChangeCenter={handleChangeCenter.bind(null, '1')}
						/>
						<span className={classes.sectionCameraItemImageCaption}>{`${caption}1`}</span>
					</Box>
				</Grid>
			</Grid>
			<Box className={classes.infoContent}>
				<div className={classes.title}>
					Уточните центр зрачков на <span>{label}</span>
				</div>
				<div className={classes.message}>
					Выберите метку нужного размера и передвиньте её по центру зрачка. Следующее фото по кнопке “Далее”. Для
					сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.
				</div>
				<Box mt={5}/>
				<ContrastSlider
					value={contrast}
					min={-100}
					max={100}
					label="Контрастность"
					onChange={handleChangeContrast}
				/>
				<Box mt={6}/>
				<ContrastSlider
					value={dotPercentSize}
					min={-10}
					max={10}
					label="Размер зрачка"
					onChange={handleChangeDotPercentSize}
				/>
				<Box mt={6}/>
				<ContrastSlider
					value={zoom}
					min={0}
					max={100}
					label="Приближение"
					onChange={handleChangeZoom}
				/>
			</Box>
		</Box>
	)
};
const ItemGlassFormElement = (props) => {
	const {
		blob,
		zoom,
		image,
		offset,
		contrast,
		dotPercentSize,

		center,
		initCenter,
		onInitCenter,
		onChangeCenter,
	} = props;
	const classes = useStyles();
	const classesForm = useStylesForm();

	const refImage = React.createRef();
	const [scaleSpan, setScaleSpan] = React.useState(0);
	const [scaleAction, setScaleAction] = React.useState(0);
	const [originalSize, setOriginalSize] = React.useState({width: 0, height: 0});

	const initState = async () => {

		const _size = await getOriginalSizeImage(blob);
		setOriginalSize(_size);

		let _center = {...initCenter};
		_center.x = _center.x - offset.x;
		_center.y = _center.y - offset.y;
		onInitCenter(_center);

		const {
			width: imageWidth
		} = refImage.current.getBoundingClientRect();

		setScaleSpan(_size.width / imageWidth);
		setScaleAction(imageWidth / _size.width);
	}
	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, [])

	const handleChangeCenter = (_center) => {
		onChangeCenter(_center);
	}

	const _getScaleContent = () => {
		let _scale = (dotPercentSize + 10);
		if (_scale === 10) {
			return 1
		}

		return (_scale / 100 * 10)
	}

	return (
		<>
			<img
				ref={refImage}
				src={image}
				style={{filter: `contrast(${contrast + 100}%)`}}
				className={clsx([classes.sectionCameraItemImage, '--main-image'])}
			/>
			<Box
				className={classesForm.sectionCameraItemAction}
				sx={{
					width: originalSize.width,
					height: originalSize.height,
					transform: `scale(${scaleAction})`
				}}
			>
				{Boolean(center) && (
					<Rnd
						bounds="parent"
						scale={scaleAction * (zoom)}
						resizable={false}
						enableResizing={0}
						className={[classesForm.rndComponent, 'panningDisabled']}
						position={{x: center?.x, y: center?.y}}
						size={{width: 0, height: 0}}
						onDragStop={(e, d) => handleChangeCenter({x: d.x, y: d.y})}
					>
						<div
							style={{transform: `scale(${_getScaleContent()})`}}
						>
							<img src={require('../../assets/image/svg/frame-measurement/center-glass-for-step.svg').default}/>
						</div>
					</Rnd>
				)}
			</Box>
		</>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: 32
	},
	calcContent: {
		display: "flex",
		flexDirection: "column",
		gap: 16,
		flex: 1,
	},
	infoContent: {
		width: "100%",
		maxWidth: 280,
	},
	title: {
		fontWeight: "500",
		fontSize: "24px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",

		"& span": {
			color: palette.primary.main
		}
	},
	message: {
		marginTop: 12,

		fontWeight: "500",
		fontSize: "15px",
		lineHeight: "130%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},

	sectionCamera: {
		display: "flex",
		alignItems: "center",
		border: "2px solid #E6E7EA",
		padding: 6,
		boxSizing: "border-box",
		background: "white",
		borderRadius: 24,
		gap: 28,
		cursor: "pointer",

		"&:hover": {
			background: "#210323",
			borderColor: "#A72681",

			"& .sectionCameraLabel": {
				color: "white",
			}
		},
	},
	sectionCameraLabel: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,

		color: "#210323",
		fontSize: "24px",
		fontWeight: "500",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
	},
	sectionCameraItem: {
		display: "flex",
		width: "100%",
		maxWidth: 320,
		borderRadius: 18,
		overflow: "hidden",
		position: "relative",
	},
	sectionCameraItemImage: {
		maxWidth: "100%",
		maxHeight: "100%"
	},
	sectionCameraItemImageCenter: {
		pointerEvents: "none",
		position: "absolute",
		zIndex: 2,
		transformOrigin: "0% 0%",

		"& span": {
			position: "absolute",
			width: 1, height: 1,
		},
		"& span:after": {
			content: "''",
			position: "absolute",
			top: "50%", left: "50%",
			width: 32, height: 32,
			transform: "translate(-50%, -50%)",
			backgroundImage: `url(${require('../../assets/image/svg/frame-measurement/center-glass-for-step.svg').default})`,
			backgroundSize: "contain",
			backgroundPosition: "center",
		},
	},
	sectionCameraItemImageCaption: {
		position: "absolute",
		left: "10px",
		bottom: "10px",
		zIndex: "2",
		pointerEvents: "none",
		fontWeight: "500",
		fontSize: "12px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#FFFFFF",
		textShadow: "0px 1.40496px 11.2397px rgba(0, 0, 0, 0.5)",
	}
}));
const useStylesForm = makeStyles(() => ({
	sectionCameraItem: {
		display: "flex",
		width: "100%",
		borderRadius: 24,
		overflow: "hidden",
		position: "relative",
		background: "white",
		padding: 8,
		boxSizing: "border-box",
		border: "1px solid #E6E7EA",
		boxShadow: "0px 4px 20px 0px #2103230A",

		"& .--main-image": {
			borderRadius: 18,
		}
	},
	sectionCameraItemAction: {
		position: "absolute",
		zIndex: 2,
		top: 8, left: 8,
		transformOrigin: "0% 0%"
	},
	rndComponent: {
		display: "flex!important",
		alignItems: "center",
		justifyContent: "center",
		userSelect: "none",
		borderRadius: 18,

		"& div": {
			width: 32,
			height: 32,
		},
		"& img": {
			userSelect: "none",
			pointerEvents: "none"
		}
	}
}));

export default ContourRefinement
