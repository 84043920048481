import React from "react";
import {
	Box,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const BoxInformationMessage = ({ message }) => {
	const classes = useStyles();
	return (
		<Box
			className={classes.root}
		>{ message }</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		padding: "20px 32px",
		boxSizing: "border-box",
		background: "white",
		border: "1px solid #E6E7EA",
		boxShadow: "0px 4px 20px 0px #2103230A",
		borderRadius: 24,

		fontSize: "15px",
		fontWeight: "500",
		lineHeight: "19.5px",
		letterSpacing: "-0.03em",
		color: "#828698",
	}
}));

export default BoxInformationMessage
