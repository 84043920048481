import React, {Component} from "react";
import {
	Box,
	Grid,
	Container,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {palette} from "../../theme/common";
import {Link} from "react-router-dom";
import {HeaderMini} from "../../components";

const items = [
	{
		label: "Новый заказ",
		path: "/orders/created",
		icon: require('../../assets/image/svg/dashboard/new-order.svg').default
	},
	{label: "Список заказов", path: "/orders", icon: require('../../assets/image/svg/dashboard/list-order.svg').default},
	{
		label: "Оборудование",
		path: "/equipment",
		icon: require('../../assets/image/svg/dashboard/equiment.svg').default,
		roles: ['admin']
	},
	{
		label: "Калибровка",
		path: "/calibration",
		icon: require('../../assets/image/svg/dashboard/calibration.svg').default
	},
	{
		label: "Деморежим",
		path: "/orders/created?order=demo",
		icon: require('../../assets/image/svg/dashboard/demo-order.svg').default
	},
	{label: "Настройки", path: "/settings", icon: require('../../assets/image/svg/dashboard/settings.svg').default},
];

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount = () => {
	}

	render() {
		const {
			userRole
		} = this.props;

		return (
			<Box>
				<Container maxWidth="xl">
					<Grid container spacing={4}>
						{items
							.filter((t) => {
								if ((t?.roles || []).length <= 0) {
									return true
								}
								return (t?.roles || []).includes(userRole)
							})
							.map((item, index) => (
								<Grid
									key={`item-${index}`}
									item
									xs={4}
								>
									<NavigationCard
										item={item}
									/>
								</Grid>
							))}
					</Grid>
				</Container>
			</Box>
		);
	}
}
const NavigationCard = ({item}) => {
	const classes = useStyles();

	return (
		<Link
			to={item.path}
			className={classes.navigationCard}
		>
			<Box className={classes.navigationCardMedia}>
				<Box className={classes.navigationCardIcon}>
					<img src={item.icon}/>
				</Box>
			</Box>
			<div className={classes.navigationCardTitle}>
				{item?.label}
			</div>
		</Link>
	)
}

const useStyles = makeStyles(() => ({
	navigationCard: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: 40,
		paddingBottom: 32,
		border: "1px solid #E6E7EA",
		borderRadius: "24px",
		background: "white",
		boxShadow: "0px 4px 20px 0px #2103230A",
		transition: "box-shadow 0.1s",

		"&:hover": {
			boxShadow: "0px 4px 15px 4px #2103232b"
		}
	},
	navigationCardMedia: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 110,
		height: 110,
	},
	navigationCardIcon: {
		width: 80, height: 80,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #A726811A",
		background: "#A7268112",
		borderRadius: "100%"
	},
	navigationCardTitle: {
		fontSize: 28,
		lineHeight: "100%",
		textAlign: "center",
		fontWeight: "500",
		color: palette.black
	},
}))

export default Dashboard
export {
	items as navigationMenu
}
