import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  Container,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  PersonAddAltOutlined as PersonAddAltOutlinedIcon
} from "@mui/icons-material";
import {
  Table,
  DialogForm
} from "./components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {
  DialogConfirmation
} from "../../components";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {
        page: 1
      },
      pagination: {
        total: 1
      },
      isLoad: true,
      isBackdrop: false
    };
    this.refDialogForm = React.createRef();
    this.refDialogConfirmation = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    this.setState({ isLoad: true })

    const res = await agent.get(`/users?limit=20&page=${this.state.filter.page}`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const pagination = {
      total: Math.ceil(res?.count / 20)
    }

    this.setState({
      data: res?.users || [],
      pagination,
      isLoad: false
    })
  }

  editUser = async (form, isSubmit) => {
    if (!isSubmit) {
      let initForm = {
        id: form.id,
        login: form.login,
        partnerId: form.partnerId,
        password: ""
      }
      this.refDialogForm.current.open({
        form: initForm,
        onSubmit: this.editUser.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true });
    let body = {
      login: form.login,
    };
    if (form.password) {
      body.password = form.password;
    }

    const res = await agent.patch(`/users/${ form.id }`, body).then((res) => {
      return true
    }).catch(() => {
      return false
    })
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }

    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.success,
      message: allTranslations("Пользователь успешно изменен")
    });
  };
  createUser = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        onSubmit: this.createUser.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true })

    delete form.login;
    const res = await agent.post('/users', form).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false })
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }
    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Пользователь создан')
    });
  };
  deleteUser = async (userId, isConfig) => {
    if (!isConfig) {
      this.refDialogConfirmation.current.open({
        message: allTranslations('Вы действительно хотите удалить пользователя?'),
        onSubmit: this.deleteUser.bind(this, userId, true)
      })
      return
    }

    this.setState({ isBackdrop: true });

    const res = await agent.delete(`/users/${userId}`).then((res) => {
      return true
    }).catch(() => {
      return false
    })
    if (!res) {
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }

    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Пользователь успешно удален')
    });
  }

  changeFilter = async (filter) => {
    await this.setState({ filter });
    await this.getData();
  }

  render() {
    const {
      data,
      filter,
      pagination,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="xl">

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">Пользователи</Typography>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              onClick={this.createUser}
            >
              <PersonAddAltOutlinedIcon
                sx={{ color: "white", opacity: 0.5, fontSize: 20, marginRight: 1.5 }}
              />
              Добавить пользователя
            </Button>
          </Grid>
        </Grid>
        <Box mt={2}/>
        <Table
          data={data}
          filter={filter}
          pagination={pagination}
          isLoad={isLoad}

          onEdit={this.editUser}
          onRemove={this.deleteUser}
          onChangePage={this.changeFilter}
        />

        <DialogForm ref={this.refDialogForm}/>
        <DialogConfirmation ref={this.refDialogConfirmation}/>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

export default Users
