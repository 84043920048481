import React from "react";
import {
	Box,
	Grid,
	FormLabel,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const ClientInfo = (props) => {
	const { patient } = props;
	const classes = useStyles();

	return (
		<Box width="100%" maxWidth="920px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography className={classes.clientLabel}>Фамилия пациента</Typography>
					<Typography className={classes.clientValue}>{ patient?.lastName || 'Н/Д' }</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.clientLabel}>Имя пациента</Typography>
					<Typography className={classes.clientValue}>{ patient?.firstName || 'Н/Д' }</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.clientLabel}>Отчество пациента</Typography>
					<Typography className={classes.clientValue}>{ patient?.patronymic || 'Н/Д' }</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.clientLabel}>Email</Typography>
					<Typography className={classes.clientValue}>{ patient?.email || 'Н/Д' }</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.clientLabel}>Телефон</Typography>
					<Typography className={classes.clientValue}>{ patient?.phone || 'Н/Д' }</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	clientLabel: {
		fontSize: "20px",
		lineHeight: "120%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},
	clientValue: {
		marginTop: 6,
		border: "1px solid #E6E7EA",
		boxShadow: "0px 2px 20px 0px #00000008",
		padding: "15px 20px",
		boxSizing: "border-box",
		background: "white",
		borderRadius: 12,

		fontSize: "18px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",
	},
}));

export default ClientInfo
