import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Radio,
  Button,
  Tooltip,
  Skeleton,
  Checkbox,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  EditOutlined as EditIcon,
  DeleteOutline as DeleteIcon
} from "@mui/icons-material";
import moment from "moment";
import allTranslations from "../../../../locales/allTranslations";

const TableCustom = (props) => {
  const {
    data,
    filter,
    pagination,
    isLoad,
    onEdit,
    onDelete,
    onEditSingleField
  } = props;

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>ID {allTranslations('внешний')}</TableCell>
            <TableCell>ID {allTranslations('внутренний')}</TableCell>
            <TableCell>{allTranslations('Адрес')}</TableCell>
            <TableCell align="center">{allTranslations('Откалиброван')}</TableCell>
            <TableCell align="center">{allTranslations('Сеть включена')}</TableCell>
            <TableCell align="right">{allTranslations('Запланированная дата калибровки')}</TableCell>
            <TableCell align="right">{allTranslations('Последняя дата калибровки')}</TableCell>
            <TableCell align="right">{allTranslations('Дата обновления')}</TableCell>
            <TableCell align="right">{allTranslations('Дата создания')}</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(isLoad) && <TableRowLoading/>}
          {Boolean(!isLoad) && data.map((item, index) => <TableRowElement
            key={`TableRowElement-${index}`}
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
            onEditSingleField={onEditSingleField}
          />)}
        </TableBody>
      </Table>

      <Grid container mt={2} justifyContent="flex-end">
        <Grid item>
          <Pagination
            page={filter.page}
            count={pagination.total}
          />
        </Grid>
      </Grid>

    </>
  )
}
const TableRowElement = React.memo((props) => {
  const {
    item,
    onEdit,
    onDelete,
    onEditSingleField
  } = props;

  const handleChangeNetworkIsEnabled = async (value) => {
    onEditSingleField(item.id, {
      networkIsEnabled: value
    })
  }

  return (
    <TableRow>
      <TableCell sx={{whiteSpace: "nowrap"}}>{item.id}</TableCell>
      <TableCell>
        <span style={{maxWidth: '160px', display: 'block', wordBreak: 'break-word'}}>{item.externalId}</span>
      </TableCell>
      <TableCell>
        {item.editableId}
      </TableCell>
      <TableCell>{item.locationAddress}</TableCell>
      <TableCell align="center">
        <Radio checked={item.isCalibrated}/>
      </TableCell>
      <TableCell align="center">
        <Radio
          checked={item.networkIsEnabled}
          onChange={handleChangeNetworkIsEnabled.bind(this, !item.networkIsEnabled)}
        />
      </TableCell>
      <TableCell align="right">
        {moment(item.plannedCalibrationDate).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell align="right">
        {moment(item.lastCalibrationAt).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell align="right">
        {moment(item.updatedAt).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell align="right">
        {moment(item.createdAt).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell align="right">
        <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
          <Grid item>
            <Tooltip title={allTranslations('Редактировать')} arrow>
              <Button variant="tableAction" color="primary" onClick={onEdit.bind(this, item, false)}>
                <EditIcon/>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={allTranslations('Удалить')} arrow>
              <Button variant="tableAction" color="error" onClick={onDelete.bind(this, item.id, false)}>
                <DeleteIcon/>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
});
const TableRowLoading = React.memo(() => {
  return (
    <>
      {new Array(10).fill(1).map((t, i) => (
        <TableRow key={`loading-${i}`}>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
        </TableRow>
      ))}
    </>
  )
})

export default React.memo(TableCustom)
