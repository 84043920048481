import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	TransformWrapper,
	TransformComponent
} from "react-zoom-pan-pinch";
import {palette} from "../../../../theme/common";
import {getOriginalSizeImage} from "../../../../helpers/snapshotVideo";
import {ContrastSlider, SwitchCustom} from "../../../../components";
import zim from "zimjs";
import clsx from "clsx";
import ResetChanged from "../common/ResetChanged";

class ContourClarification extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fullItem: null
		};
	}

	openItem = (fullItem) => {
		this.setState({
			fullItem
		});
	};
	closeItem = () => {
		this.setState({
			fullItem: null
		})
	};
	submitItem = (changedPoints) => {
		const {fullItem} = this.state;
		this.props.onChange(`cont${fullItem?.cam}${fullItem.dir}`, changedPoints);
		this.nextStepEdit();
	};
	nextStepEdit = () => {
		const { fullItem } = this.state;
		const {
			cont0l,
			rects0,
			cont1r,
			rects1,
			cont1l,
			blobLeftLeft,
			blobRightLeft,
			blobRightRight,
		} = this.props;
		const index = fullItem.index;
		if (index === 0) {
			this.openItem({
				index: 1,
				blob: blobLeftLeft,
				nodes: cont0l,
				rect: rects0?.rect_0l,
				cam: 0,
				dir: 'l'
			})
		}
		if (index === 1) {
			this.openItem({
				index: 2,
				blob: blobRightRight,
				nodes: cont1r,
				rect: rects1?.rect_1r,
				cam: 1,
				dir: 'r'
			})
		}
		if (index === 2) {
			this.openItem({
				index: 3,
				blob: blobRightLeft,
				nodes: cont1l,
				rect: rects1?.rect_1l,
				cam: 1,
				dir: 'l'
			})
		}
		if (index === 3) {
			this.closeItem();
		}
	};

	_getKeyForFormItemContourClarification = () => {
		const {fullItem} = this.state;
		return `FormItemContourClarification-${fullItem?.cam}-${fullItem?.dir}-${(fullItem?.nodes || []).length}`
	};

	render() {
		const {
			blobLeftLeft,
			blobLeftRight,
			blobRightLeft,
			blobRightRight,

			rects0,
			rects1,
			cont0l,
			cont0r,
			cont1l,
			cont1r,
		} = this.props;
		const {
			fullItem
		} = this.state;

		if (Boolean(fullItem)) {
			return (
				<FormItemContourClarification
					key={this._getKeyForFormItemContourClarification()}
					keyItem={this._getKeyForFormItemContourClarification()}
					item={fullItem}
					onClose={this.closeItem}
					onSubmit={this.submitItem}
				/>
			)
		}
		return (
			<ItemsContentContourClarification
				blobLeftLeft={blobLeftLeft}
				blobLeftRight={blobLeftRight}
				blobRightLeft={blobRightLeft}
				blobRightRight={blobRightRight}

				rects0={rects0}
				rects1={rects1}

				cont0l={cont0l}
				cont0r={cont0r}
				cont1l={cont1l}
				cont1r={cont1r}

				onOpen={this.openItem}
			/>
		)
	}
}

const ItemsContentContourClarification = (props) => {
	const {
		blobLeftLeft,
		blobLeftRight,
		blobRightLeft,
		blobRightRight,

		rects0,
		rects1,

		cont0l,
		cont0r,
		cont1l,
		cont1r,

		onOpen
	} = props;
	const classes = useStyles();
	const handleOpen = (_item) => {
		if (_item?.disabled) {
			return
		}
		onOpen(_item);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.calcContent}>
				<Box
					className={clsx({
						[classes.imageBox]: true,
						'--init-image': Boolean(!blobLeftRight),
						'--loading': Boolean(!blobLeftRight || !cont0r),
					})}
					onClick={handleOpen.bind(null, {
						index: 0,
						blob: blobLeftRight,
						nodes: cont0r,
						rect: rects0?.rect_0r,
						cam: 0,
						dir: 'r',
						disabled: Boolean(!blobLeftRight || !cont0r)
					})}
				>
					{Boolean(blobLeftRight) && (
						<ItemsContentContourClarificationElement
							key={`ItemsContentContourClarificationElement-cont0r-${(cont0r || []).length}`}
							blob={blobLeftRight}
							nodes={cont0r}
							rect={rects0?.rect_0r}
						/>
					)}
					<span className={classes.imageBoxCaption}>П-0</span>
				</Box>
				<Box
					className={clsx({
						[classes.imageBox]: true,
						'--init-image': Boolean(!blobLeftLeft),
						'--loading': Boolean(!blobLeftLeft || !cont0l)
					})}
					onClick={handleOpen.bind(null, {
						index: 1,
						blob: blobLeftLeft,
						nodes: cont0l,
						rect: rects0?.rect_0l,
						cam: 0,
						dir: 'l',
						disabled: Boolean(!blobLeftLeft || !cont0l)
					})}
				>
					{Boolean(blobLeftLeft) && (
						<ItemsContentContourClarificationElement
							key={`ItemsContentContourClarificationElement-cont0l-${(cont0l || []).length}`}
							blob={blobLeftLeft}
							nodes={cont0l}
							rect={rects0?.rect_0l}
						/>
					)}
					<span className={classes.imageBoxCaption}>Л-0</span>
				</Box>

				<Box
					className={clsx({
						[classes.imageBox]: true,
						'--init-image': Boolean(!blobRightRight),
						'--loading': Boolean(!blobRightRight || !cont1r)
					})}
					onClick={handleOpen.bind(null, {
						index: 2,
						blob: blobRightRight,
						nodes: cont1r,
						rect: rects1?.rect_1r,
						cam: 1,
						dir: 'r',
						disabled: Boolean(!blobRightRight || !cont1r)
					})}
				>
					{Boolean(blobRightRight) && (
						<ItemsContentContourClarificationElement
							key={`ItemsContentContourClarificationElement-cont1r-${(cont1r || []).length}`}
							blob={blobRightRight}
							nodes={cont1r}
							rect={rects1?.rect_1r}
						/>
					)}
					<span className={classes.imageBoxCaption}>П-1</span>
				</Box>
				<Box
					className={clsx({
						[classes.imageBox]: true,
						'--init-image': Boolean(!blobRightLeft),
						'--loading': Boolean(!blobRightLeft || !cont1l)
					})}
					onClick={handleOpen.bind(null, {
						index: 3,
						blob: blobRightLeft,
						nodes: cont1l,
						rect: rects1?.rect_1l,
						cam: 1,
						dir: 'l',
						disabled: Boolean(!blobRightLeft || !cont1l)
					})}
				>
					{Boolean(blobRightLeft) && (
						<ItemsContentContourClarificationElement
							key={`ItemsContentContourClarificationElement-cont1l-${(cont1l || []).length}`}
							blob={blobRightLeft}
							nodes={cont1l}
							rect={rects1?.rect_1l}
						/>
					)}
					<span className={classes.imageBoxCaption}>Л-1</span>
				</Box>
			</Box>
			<Box className={classes.infoContent}>
				<Typography className={classes.title}>Уточните контур линз</Typography>
				<Typography className={classes.message}>
					Нажмите на одну из фотографий. Расположите метки по периметру линзы. Каждая метка двигается по прямой линии от
					центра, передвигайте стрелками “влево” “вправо” на клавиатуре или мышкой.
					Переключение между метками клавишам F2/F3 или кликом мышки.<br/>
					Следующее фото по кнопке “Далее”.<br/>
					Для сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”.
				</Typography>
				<Box mt={4}/>
			</Box>
		</Box>
	)
};
const ItemsContentContourClarificationElement = (props) => {
	const {
		blob,
		rect,
		nodes
	} = props;
	const classes = useStyles();

	const refImage = React.createRef();
	const [imagePath] = React.useState(() => {
		return URL.createObjectURL(blob)
	});
	const [scaleAction, setScaleAction] = React.useState(0);
	const initState = async () => {
		if (!refImage.current) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageClientWidth,
			height: imageClientHeight,
		} = refImage.current.getBoundingClientRect();
		if (imageClientWidth <= 0 || imageClientHeight <= 0) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageOriginalWidth,
			height: imageOriginalHeight,
		} = await getOriginalSizeImage(blob);

		// setOriginalSize({
		// 	width: imageOriginalWidth,
		// 	height: imageOriginalHeight
		// });
		// setScaleSpan(imageOriginalWidth / imageClientWidth);
		setScaleAction(imageClientWidth / imageOriginalWidth);
	};
	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, [blob, imagePath]);

	return (
		<Box className={classes.imageBoxItem}>
			<img ref={refImage} src={imagePath}/>
			{Boolean((nodes || []).length > 0) && (
				<>
					<div className={classes.imageBoxItemCenter}/>
					<Box
						className={classes.imageBoxAction}
						sx={{
							marginTop: `${(rect.y * -1) * scaleAction}px`,
							marginLeft: `${(rect.x * -1) * scaleAction}px`,
							transform: `scale(${scaleAction})`
						}}
					>
						{(nodes || []).map((t) => (
							<div style={{top: t.y, left: t.x}}/>
						))}
					</Box>
				</>
			)}
		</Box>
	)
};

const FormItemContourClarification = (props) => {
	const {
		item,
		keyItem,

		onClose,
		onSubmit,
	} = props;
	const {
		blob
	} = item;
	const classes = useStyles();

	const refImage = React.createRef();
	const [imagePath] = React.useState(() => {
		return URL.createObjectURL(blob);
	});

	const [contrast, setContrast] = React.useState(0);
	const handleChangeContrast = (_contrast) => {
		setContrast(_contrast);
	};

	const [isAdvancedMarkup, setAdvancedMarkup] = React.useState(true);
	const handleChangeAdvancedMarkup = (val) => {
		setAdvancedMarkup(val);

		if (!val) {
			const centerDot = document.getElementById('editCenterDot');
			refZoom.zoomToElement(centerDot, -10);
		}
	}

	const [refZoom, setRefZoom] = React.useState(null);
	const handleSetZoom = (_item) => {
		setRefZoom(_item);
	}

	const [zoom, setZoom] = React.useState(1);
	const [zoomEnabled, setZoomEnabled] = React.useState(true);
	const handleStopZoom = (event) => {
		setZoom(event.state?.scale || 1)
	};
	const handleChangeZoom = (_zoom) => {

		let isIn = Boolean(_zoom > zoom);
		if (isIn) {
			refZoom.zoomIn(_zoom - zoom);
		} else {
			refZoom.zoomOut(_zoom + zoom);
		}

		setZoom(_zoom);
	};
	const handleChangeZoomEnabled = (_zoomEnabled) => {
		setZoomEnabled(_zoomEnabled);
	};
	const handleChangeZoomPosition = (activeIndexPoint) => {
		const _isAdvancedMarkup = document.getElementById('isAdvancedMarkup').getAttribute('data-value') === 'true';
		if (!refZoom || !_isAdvancedMarkup) {
			return
		}
		if (activeIndexPoint === -1) {
			const centerDot = document.getElementById('editCenterDot');
			refZoom.zoomToElement(centerDot, -10);
			return;
		}
		const element = document.getElementById(`clarification-points-small-${ activeIndexPoint }`);
		refZoom.zoomToElement(element, 4);
	};

	const [isLineIllumination, setLineIllumination] = React.useState(false);
	const handleChangeLineIllumination = (_value) => {
		setLineIllumination(_value);

		window.dispatchEvent(new CustomEvent('change-line-illumination', {
			detail: {
				value: _value
			}
		}))
	}

	const [points, setPoints] = React.useState([]);
	const [scaleSpan, setScaleSpan] = React.useState(0);
	const [scaleAction, setScaleAction] = React.useState(0);
	const [originalSize, setOriginalSize] = React.useState({width: 0, height: 0});
	const initState = async () => {
		const imgRoot = document.getElementById('imgRoot');
		if (!imgRoot) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageClientWidth,
			height: imageClientHeight,
		} = imgRoot.getBoundingClientRect();
		if (imageClientWidth <= 0 || imageClientHeight <= 0) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		const {
			width: imageOriginalWidth,
			height: imageOriginalHeight,
		} = await getOriginalSizeImage(blob);

		setOriginalSize({
			width: imageOriginalWidth,
			height: imageOriginalHeight
		});
		setScaleSpan(imageOriginalWidth / imageClientWidth);
		setScaleAction(imageClientWidth / imageOriginalWidth);

		const centerPoint = {
			x: imageOriginalWidth / 2,
			y: imageOriginalHeight / 2,
		};
		const _points = item?.nodes || [];
		const getStartEndPoint = function (x1, y1, x2, y2) {
			const isAfterCenterX = x2 > x1;
			const isAfterCenterY = y2 > y1;

			const point2 = {x: x2, y: y2};
			const point1 = {x: x1, y: y1};

			const canvasWidth = imageOriginalWidth;
			const canvasHeight = imageOriginalHeight;

			const a = (point2.y - point1.y) / (point2.x - point1.x);
			const b = (point1.y * point2.x - point2.y * point1.x) / (point2.x - point1.x);

			const leftSideY = b;
			const rightSideY = (canvasWidth * a) + b;
			let topSideX = (-b) / a;
			let bottomSideX = (canvasHeight - b) / a;

			if ([Infinity, -Infinity].includes(a)) {
				topSideX = bottomSideX = point1.x;
			}

			const edgePoints = [
				{x: 0, y: leftSideY},
				{x: canvasWidth, y: rightSideY},
				{x: topSideX, y: 0},
				{x: bottomSideX, y: canvasHeight}
			].filter(({x, y}) => {
				if (isAfterCenterX) {
					return x > x1
				}
				if (isAfterCenterY) {
					return y > y1
				}
				return x >= 0 && x <= canvasWidth && y >= 0 && y <= canvasHeight
			});

			return edgePoints[0]
		};
		let points = [];
		_points.map((_point) => {
			const offsetX = item?.rect?.x;
			const offsetY = item?.rect?.y;
			const endPoint = getStartEndPoint(centerPoint.x, centerPoint.y, _point.x - offsetX, _point.y - offsetY)

			points.push({
				end: endPoint,
				start: centerPoint,
				value: {
					x: _point.x - offsetX,
					y: _point.y - offsetY,
				}
			})
		});
		setPoints(points);
	};
	const handleChangeOnePoint = (point, index) => {
		let _points = [...points];
		_points[index]['value'] = point;
		_points[index]['changed'] = true;
		setPoints(_points);
	};
	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, [blob, imagePath]);

	const handleCallEventPrevNextPoint = (_code) => {
		window.dispatchEvent(new CustomEvent('keydown', {
			detail: {
				code: _code
			}
		}))
	};

	const handleSubmit = () => {

		const rect = item.rect;
		const _submitPoints = [...(points || [])]
			.map((t) => {
				return t.value
			})
			.map((t) => {
				return {
					x: t.x + (rect?.x || 0),
					y: t.y + (rect?.y || 0),
				}
			});

		onSubmit(_submitPoints);
	};

	return (
		<Box
			className={classes.root}
			sx={{justifyContent: "flex-end"}}
		>
			<Box className={classes.boxEdit}>
				<TransformWrapper
					disabled={!zoomEnabled}
					disablePadding={true}
					panning={{
						excluded: ['panningDisabled'],
						velocityDisabled: false
					}}
					centerZoomedOut={true}
					onZoomStop={handleStopZoom}
					onInit={handleSetZoom}
				>
					<TransformComponent>
						<img
							ref={refImage}
							id="imgRoot"
							src={imagePath}
							className={classes.boxEditImage}
							style={{
								filter: `contrast(${1 + (contrast / 10)})`
							}}
						/>
						<Box
							className={classes.boxEditContent}
							sx={{
								width: originalSize.width,
								height: originalSize.height,
								transform: `scale(${scaleAction}) translate(-50%, -50%)`
							}}
						>
							<Box className={classes.boxEditAction}>
								{Boolean(points.length > 0) && (
									<>
										<FormItemContourClarificationAction
											key={`${ keyItem }-action`}
											points={points}
											originalSize={originalSize}
											onChangePoint={handleChangeOnePoint}
											onChangeZoomEnabled={handleChangeZoomEnabled}
											onChangeActiveIndex={handleChangeZoomPosition}
										/>
										<FormItemContourClarificationPointsSmall points={points}/>
									</>
								)}
							</Box>
							<Box
								id="editCenterDot"
								className={classes.boxEditCenterDot}
								style={{transform: `scale(${scaleSpan})`}}
							/>
						</Box>
					</TransformComponent>
				</TransformWrapper>
			</Box>
			<Box className={classes.infoContent}>
				<Typography className={classes.title}>Индивидуальное уточнение контура правой линзы</Typography>
				<Typography className={classes.message}>
					Индивидуальное уточнение левого контура линзы. Передвиньте метки по периметру линзы.
				</Typography>
				<Box mt={5}/>
				<ContrastSlider value={contrast} min={-10} max={10} label="Контрастность" onChange={handleChangeContrast}/>
				<Box mt={5}/>
				<ContrastSlider value={zoom} min={1} max={8} step={1} label="Приближение" onChange={handleChangeZoom}/>
				<Box mt={5}/>
				<SwitchCustom label="Подсветка линий" value={isLineIllumination} onChange={handleChangeLineIllumination}/>
				<Box mt={2}/>
				<SwitchCustom label="Расширенная разметка" value={isAdvancedMarkup} onChange={handleChangeAdvancedMarkup}/>
				<div id="isAdvancedMarkup" data-value={isAdvancedMarkup}/>
				<Box mt={2}/>

				<Grid container spacing={1} wrap="nowrap">
					<Grid item>
						<Button
							className={classes.buttonPrevNext}
							variant="contained"
							onClick={handleCallEventPrevNextPoint.bind(null, 'F2')}
						>
							<span className="__badge">F2</span>
							Предыдущая<br/>точка
						</Button>
					</Grid>
					<Grid item>
						<Button
							className={classes.buttonPrevNext}
							variant="contained"
							onClick={handleCallEventPrevNextPoint.bind(null, 'F3')}
						>
							<span className="__badge">F3</span>
							Следующая<br/>точка
						</Button>
					</Grid>
				</Grid>

				<Box mt="auto"/>
				<Box mb={1} sx={{opacity: 0, pointerEvents: "none"}}>
					<Button
						id="ContourClarificationSubmit"
						size="small"
						variant="contained"
						fullWidth
						onClick={handleSubmit}
					>
						Сохранить изменения
					</Button>
				</Box>
				<ResetChanged
					onClick={onClose}
				/>
			</Box>
		</Box>
	)
};
const FormItemContourClarificationAction = (props) => {
	const {
		points,
		originalSize,
		onChangePoint,
		onChangeZoomEnabled,
		onChangeActiveIndex
	} = props;
	let activeIndexDot = -1;
	let elementIframe = null;
	let pathItems = {};
	let circleItems = {};
	const countPoints = points.length;

	const refRoot = React.createRef();
	const initZimFrame = async () => {
		if (points.length <= 0) {
			return
		}
		if (!refRoot || !refRoot?.current?.parentElement) {
			setTimeout(async () => {
				await initZimFrame();
			}, 100)
			return
		}

		const sizes = refRoot.current.parentElement.getBoundingClientRect();
		if (sizes.width <= 0 || sizes.height <= 0) {
			setTimeout(async () => {
				await initZimFrame();
			}, 100)
			return
		}

		if (!!elementIframe) {
			return
		}

		// Инициализация
		elementIframe = new zim.Frame({
			scaling: "FormItemContourClarificationAction",
			width: originalSize.width,
			height: originalSize.height,
			sensors: true
		});
		elementIframe.on('ready', async function () {
			await setPointsToZimFrame();
		});
	};
	const setPointsToZimFrame = async () => {
		const stage = elementIframe.stage;
		pathItems = {};
		await Promise.all((points || []).map(async (point, index) => {
			// Создание пути по которой будет ходить точка разметки
			const path = new zim.Squiggle({
				color: "transparent",
				points: [
					[point.start.x, point.start.y],
					[point.end.x, point.end.y],
				],
				interactive: false,
				thickness: 1,
				StickThickness: 1,
				handleSize: 1,
				strokeObj: {miterLimit: 1, ignoreScale: true},
				onTop: false,
				showControls: false
			});
			const circle = new zim.Circle({
				color: "transparent",
				borderColor: "transparent",
				radius: 15
			})
				.addTo(path)
				.loc(point.value.x, point.value.y)
				.animate({
					props: {
						path: path,
						x: point.value.x,
						y: point.value.y
					},
					obj: {
						x: point.value.x,
						y: point.value.y
					},
					wait: 0,
					drag: true
				});
			path.on("added", async () => {
				await new Promise(r => setTimeout(r, 1000));

				const distLine = Math.sqrt(Math.pow((point.start.x - point.end.x), 2) + Math.pow((point.start.y - point.end.y), 2));
				const distValue = Math.sqrt(Math.pow((point.start.x - point.value.x), 2) + Math.pow((point.start.y - point.value.y), 2));

				circle._percentComplete = ((distValue / distLine) * 100);
				circle.x = point.value.x;
				circle.y = point.value.y;

				circle.color = new zim.RadialColor(['#FFE500', 'rgba(255,255,255,0.01)'], [0, 0.1], 0, 0, 0, 0, 0, 20);
				circle.borderColor = "#FFE500";

				await circle.loc(point.value.x, point.value.y);
				await circle.uncache();
				await stage.update();
			});

			circle.on("click", () => {
				activeIndexDot = index;
				handleEventChangeActiveIndex();
			});
			circle.on("pressup", () => {
				handleEventChangePointFromCircle(index);
			});
			circle.on("mouseout", () => {
				onChangeZoomEnabled(true);
			});
			circle.on("mousedown", () => {
				onChangeZoomEnabled(false);
			});
			circle.on("mouseover", () => {
				onChangeZoomEnabled(false);
			});
			circle.on("pressdown", () => {
				onChangeZoomEnabled(false);
			});
			circle.on("pressmove", () => {
				onChangeZoomEnabled(false);
			});

			pathItems = {
				...pathItems,
				[index]: path
			}
			circleItems = {
				...circleItems,
				[index]: circle
			}

			stage.addChild(path);
		}));
		await stage.update();
	};
	const handleEventKeyboard = (event) => {
		const _code = event?.detail?.code || event?.code;

		if (_code === 'F2') {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();

			return handlePrevActiveIndexDot();
		}
		if (_code === 'F3') {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();

			return handleNextActiveIndexDot();
		}

		if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(_code)) {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();
			return handleEventPointFromKeyboard(_code);
		}
	};
	const eventChangeLineIllumination = (event) => {
		const _val = event?.detail?.value;
		Object.keys(pathItems).map((index) => {
			const path = pathItems[index];
			path.color = Boolean(_val) ? "white" : "transparent";
			path.stage.update();
		});
	};
	React.useEffect(() => {
		(async () => {
			await window.addEventListener("keydown", handleEventKeyboard);
			await window.addEventListener("change-line-illumination", eventChangeLineIllumination);
			await initZimFrame();
		})();
		return () => {
			elementIframe.dispose();
			circleItems = {};
			activeIndexDot = -1;
			elementIframe = null;
			window.removeEventListener("keydown", handleEventKeyboard);
			window.removeEventListener("change-line-illumination", eventChangeLineIllumination);
		}
	}, []);

	const handlePrevActiveIndexDot = () => {
		let _activeIndexDot = Number.parseFloat(String(activeIndexDot));
		if (Boolean(_activeIndexDot === -1)) {
			activeIndexDot = (countPoints - 1);
			onChangeActiveIndex(countPoints - 1);
			handleEventChangeActiveIndex();
			return
		}

		_activeIndexDot = _activeIndexDot - 1;
		if (_activeIndexDot < 0) {
			activeIndexDot = (-1);
			onChangeActiveIndex(-1);
			handleEventChangeActiveIndex();
			return;
		}

		activeIndexDot = (_activeIndexDot);
		onChangeActiveIndex(_activeIndexDot);
		handleEventChangeActiveIndex();
	};
	const handleNextActiveIndexDot = () => {
		let _activeIndexDot = Number.parseFloat(String(activeIndexDot));
		if (_activeIndexDot === -1) {
			activeIndexDot = (0);
			onChangeActiveIndex(0);
			handleEventChangeActiveIndex();
			return
		}

		_activeIndexDot = _activeIndexDot + 1;
		if (_activeIndexDot > countPoints - 1) {
			activeIndexDot = (-1);
			onChangeActiveIndex(-1);
			handleEventChangeActiveIndex();
			return;
		}

		activeIndexDot = (_activeIndexDot);
		onChangeActiveIndex(_activeIndexDot);
		handleEventChangeActiveIndex();
	};
	const handleEventChangeActiveIndex = () => {
		Object.keys(circleItems).map((index) => {
			const circle = circleItems[index];
			const isChanged = points[index]['changed'];
			const isSelected = Boolean(String(activeIndexDot) === String(index));
			const noSelectedColor = isChanged ? "#61FF00" : "#FFE500";

			circle.color = isSelected ?
				new zim.RadialColor(['#FF00B4', 'rgba(255,255,255,0.01)'], [0, 0.1], 0, 0, 0, 0, 0, 20) :
				new zim.RadialColor([noSelectedColor, 'rgba(255,255,255,0.01)'], [0, 0.1], 0, 0, 0, 0, 0, 20);
			circle.borderColor = isSelected ? "#FF00B4" : noSelectedColor;
		});
		elementIframe.stage.update();
	};
	const handleEventPointFromKeyboard = (code) => {
		if (activeIndexDot === -1) {
			return null
		}

		const pointEnd = points[activeIndexDot]['end'];
		const pointStart = points[activeIndexDot]['start'];
		const distLine = Math.sqrt(Math.pow((pointStart.x - pointEnd.x), 2) + Math.pow((pointStart.y - pointEnd.y), 2));

		let percentValueDist = (0.5 / distLine) * 100;
		if (['ArrowUp', 'ArrowDown'].includes(code) && pointEnd.y < pointStart.y) {
			percentValueDist = percentValueDist * -1;
		}
		if (['ArrowLeft', 'ArrowRight'].includes(code) && pointEnd.x < pointStart.x) {
			percentValueDist = percentValueDist * -1;
		}

		const circle = circleItems[activeIndexDot];
		let percentComplete = circle._percentComplete;
		if (code === 'ArrowUp' || code === 'ArrowLeft') {
			percentComplete = percentComplete - percentValueDist;
		}
		if (code === 'ArrowDown' || code === 'ArrowRight') {
			percentComplete = percentComplete + percentValueDist;
		}

		circle.percentComplete = percentComplete;
		const stage = elementIframe.stage;
		if (stage?.update) {
			stage.update();
		}

		handleEventChangePointFromCircle(activeIndexDot);
	};
	const handleEventChangePointFromCircle = (indexElement) => {
		const circle = circleItems[indexElement];

		let point = {...points?.[Number.parseFloat(activeIndexDot)]?.value};
		point.x = circle.x;
		point.y = circle.y;

		onChangePoint(point, indexElement);
	};

	return (
		<div
			ref={refRoot}
			id="FormItemContourClarificationAction"
			style={{display: "flex"}}
		/>
	)
};
const FormItemContourClarificationPointsSmall = ({points}) => {
	return points.map((point, index) => (
		<div
			id={`clarification-points-small-${ index }`}
			style={{
				width: 1,
				height: 1,
				background: "red",
				borderRadius: "100%",
				position: "absolute",
				top: point.value.y,
				left: point.value.x,
			}}
		/>
	))
};

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: 32
	},
	calcContent: {
		display: "flex",
		flexWrap: "wrap",
		gap: 16, flex: 1,
	},
	imageBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",

		width: "calc((100% / 2) - 18px)",
		background: "white",
		border: "5px solid white",
		boxShadow: "0 0 0 1px #E6E7EA",
		borderRadius: 24,
		position: "relative",
		overflow: "hidden",

		"&.--init-image": {
			cursor: "progress",
			"&:before": {
				content: "'Обработка изображения'",
				color: "#A72681",
			}
		},
		"&.--loading": {
			cursor: "progress",

			"& img": {
				filter: "grayscale(1)"
			},
			"&:before": {
				content: "'Идет расчет параметров'",
				position: "absolute",
				top: "0",
				left: "0",
				width: "100%",
				height: "100%",
				zIndex: "10",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				fontSize: "20px",
				fontWeight: "500",
				lineHeight: "1",
				letterSpacing: "-0.03em",
				textAlign: "center",
				color: "white",
			}
		},
		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "100%"
		},
		"&:not(.--loading):hover": {
			borderColor: "#210323",
			boxShadow: "0 0 0 1px #A72681",
		}
	},
	imageBoxItem: {
		display: "flex",
		position: "relative",
		overflow: "hidden",
		borderRadius: 18,

		"& > img": {
			maxWidth: "100%",
			maxHeight: "100%",
		},
		"&.--loading": {}
	},
	imageBoxItemCenter: {
		content: "''",
		position: "absolute",
		top: "50%", left: "50%",
		transform: "translate(-50%, -50%)",
		width: 5, height: 5,
		borderRadius: "100%",
		background: "#FFE500"
	},
	imageBoxCaption: {
		position: "absolute",
		left: 16, bottom: 16,
		pointerEvents: "none",

		fontSize: 14,
		lineHeight: 1,
		color: "#A72681",
		fontWeight: "500",
		letterSpacing: "-0.03em",
		textShadow: "0px 0px 8px #ffffff"
	},
	imageBoxAction: {
		position: "absolute",
		top: 0, left: 0,
		width: 1520,
		height: 1520,
		userSelect: "none",
		pointerEvents: "none",
		transformOrigin: "0% 0%",

		"& > div": {
			position: "absolute",
			width: 0, height: 0,
			"&:after": {
				content: "''",
				borderRadius: "100%",
				position: "absolute",
				top: "50%", left: "50%",
				transform: "translate(-50%, -50%)",
				// backgroundColor: "#FFE500",
				width: 15, height: 15,
				border: "1px solid #FFE500",
			},
			"&:before": {
				content: "''",
				borderRadius: "100%",
				position: "absolute",
				top: "50%", left: "50%",
				transform: "translate(-50%, -50%)",
				backgroundColor: "#FFE500",
				width: 2, height: 2,
			},
		}
	},
	infoContent: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: 280,
	},

	boxEdit: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		background: "white",
		border: "5px solid white",
		boxShadow: "0 0 0 1px #E6E7EA",
		borderRadius: 24,
		position: "relative",
		overflow: "hidden",
		maxWidth: 760,
		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "100%"
		},
		"& .react-transform-wrapper": {
			display: "flex",
			width: "100%",
			height: "100%"
		},
		"& .react-transform-component": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			height: "100%"
		},
	},
	boxEditContent: {
		display: "flex",
		borderRadius: 18,
		position: "absolute",
		top: "50%", left: "50%",
		overflow: "hidden",
		transformOrigin: "0% 0%",
	},
	boxEditImage: {
		userSelect: "none",
		maxWidth: "100%",
		maxHeight: "100%",
		position: "absolute",
		top: "50%", left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: 18,
		pointerEvents: "none",
	},
	boxEditAction: {
		position: "absolute",
		top: "50%", left: "50%",
		transform: "translate(-50%, -50%)",
		width: "100%",
		height: "100%"
	},
	boxEditCenterDot: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: "50%", left: "50%",
		transform: "translate(-50%, -50%)",
		width: 0, height: 0,
		userSelect: "none",
		pointerEvents: "none",
		"&:after": {
			content: "''",
			width: 10, height: 10,
			borderRadius: "100%",
			background: "#FFE500",
			position: "absolute",
			top: "50%", left: "50%",
			transform: "translate(-50%, -50%)",
		}
	},

	title: {
		fontWeight: "500",
		fontSize: "24px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",

		"& span": {
			color: palette.primary.main
		}
	},
	message: {
		marginTop: 12,

		fontWeight: "500",
		fontSize: "15px",
		lineHeight: "130%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},
	buttonPrevNext: {
		display: "flex",
		alignItems: "center",
		gap: 8,
		padding: 14,
		background: "#A726811A",

		fontSize: 14,
		lineHeight: 1.1,
		color: "#A72681",
		textAlign: "left",

		"& .__badge": {
			backgroundColor: "#A72681",
			color: "white",
			padding: "5px 6px 2px",
			borderRadius: 4
		},
		"&:hover": {
			color: "white",
			"& .__badge": {
				backgroundColor: "white",
				color: "#A72681",
			},
		}
	},
	canvasLines: {
		position: "absolute",
		top: 0, left: 0,
		// width: "100%",
		// height: "100%",
		userSelect: "none",
		pointerEvents: "none"
	}
}));

export default ContourClarification
